import { getSearchCourse } from '../../mixway-api';
import { SearchCourseParams } from '../params';
import { DateTime } from 'luxon';
import { modifyCourse, sortByDepartureTime } from '../../../course';
import { getDepartureNo, getLineType, LINE_TYPE } from '../../mixway-utils';
import { Course, Line, Point } from '../../../../types/mixway-api/extreme';
import { northBusPoles } from '../../../../data/kozoji/north-bus-poles';
import { southBusPoles } from '../../../../data/kozoji/south-bus-poles';
import { getDefaultWalkingTime } from '../nagoya-city';
import {
  isNorthBusPole,
  isNorthEastLine,
  isSouthEastLine,
  isYutoritoLine,
} from '../../../kozoji';
import { toJSTDatetime } from '../../../datetime';
import LANDMARKS from './../../../../data/landmarks.json';

/** 高蔵寺駅のランドマーク情報。 */
const KOUZOJI_STATION_LANDMARK = LANDMARKS['kozoji'];

/**
 * のりば番号に応じて、高蔵寺駅北口のバスのりばまでの所要時間を取得する。
 * @param poleNumber のりば番号。
 * @returns バスのりばまでの所要時間。
 */
export const getWalkingTimeNorth = (poleNumber: string) => {
  const keys = Object.keys(northBusPoles);
  return keys.includes(poleNumber) ? northBusPoles[poleNumber].walking_time : 0;
};

/**
 * 高蔵寺駅南口のバスのりば番号を取得する。
 * @param lineName 路線名。
 * @returns 高蔵寺駅南口のバスのりば番号。
 */
export const getSouthBusPoleNumber = (lineName: string) => {
  // 2番のりば（かすがいシティバス）の場合。
  if (isNorthEastLine(lineName) || isSouthEastLine(lineName)) {
    return '2';
  }
  // 3番のりば（ゆとりーとライン・名古屋市営バス）の場合。
  if (isYutoritoLine(lineName)) {
    return '3';
  }
  // それ以外（1番のりば）の場合。
  return '1';
};

/**
 * のりば（路線）に応じて、高蔵寺駅南口のバスのりばまでの所要時間を取得する。
 * @param lineName 路線名。
 * @returns バスのりばまでの所要時間。
 */
export const getWalkingTimeSouth = (lineName: string) => {
  /** のりば番号。 */
  const poleNumber = getSouthBusPoleNumber(lineName);
  return southBusPoles[poleNumber].walking_time;
};

/**
 * 経路検索対象の会社名を取得する。
 * @returns 「:」つなぎの会社名。
 */
export const getCorporations = () => {
  /** 会社名の配列。 */
  const corporations = ['名古屋市営バス', '名鉄バス', '愛知県春日井市'];
  return corporations.join(':');
};

/**
 * 該当の経路が条件に合致しているかを確認する
 * @param firstLine 最初の区間。
 * @param firstPoint 最初の地点（バス停）。
 * @param depDate 出発日時。
 * @returns 経路が条件に合致しているか判定した結果。
 */
export const isMatchCourse = (
  firstLine: Line,
  firstPoint: Point,
  depDate: DateTime
) => {
  const firstLineType = getLineType(firstLine.Type);

  // 最初の区間がバス利用ではない経路を取り除くためfalseを返却する。
  if (firstLineType !== LINE_TYPE.bus) {
    return false;
  }
  /** 経路の最初のバス停の駅コード。 */
  const firstStationCode = firstPoint.Station?.code
    ? firstPoint.Station.code
    : '';
  // 基準とする日時を取得する。
  const defaultWalkingTime = getDefaultWalkingTime();
  /** 出発するバスのりば番号。 */
  const departureNo = getDepartureNo(firstLine);
  /** 最初のバス区間の名称。 */
  const firstLineName = firstLine.Name;
  const useNorthBusPole = isNorthBusPole(firstStationCode);
  // 北口・南口に応じて所要時間を取得する。
  const walkingTimeByPole = useNorthBusPole
    ? getWalkingTimeNorth(departureNo)
    : getWalkingTimeSouth(firstLineName);
  /** サイネージからの徒歩時間。 */
  const walkingTime =
    walkingTimeByPole === 0 ? defaultWalkingTime : walkingTimeByPole;
  /** サイネージを出発していなければならない期限の日時。 */
  const deadlineDate = depDate.plus({ minutes: walkingTime });
  /** 経路の最初の区間の出発日時。 */
  const firstLineDepartureDate = toJSTDatetime(
    firstLine.DepartureState.Datetime.text
  );
  return deadlineDate.toMillis() <= firstLineDepartureDate.toMillis();
};

/**
 * 条件に合致する経路を抽出する。
 * @param courses 取得した経路。
 * @returns 条件に合致する経路の配列。
 */
export const filterCourses = (
  courses: Course[],
  depDate: DateTime
): Course[] => {
  /** フィルター後の経路。 */
  const filtered = courses.filter((course) => {
    const firstLine = course.Route.Line[0];
    const firstPoint = course.Route.Point[0];
    return isMatchCourse(firstLine, firstPoint, depDate);
  });
  if (filtered.length === 0) {
    return [];
  }
  return filtered;
};

/**
 * 高蔵寺駅での経路検索を実行する。
 * @param arrCoordinate 目的地の緯度経度。
 * @param depDate 出発日時。
 * @returns 高蔵寺から指定された目的地までの経路。
 */
export const searchCourse = async (
  arrCoordinate: string,
  arrivalPointName: string,
  depDate: DateTime
) => {
  const corporations = getCorporations();
  /** 経路検索のパラメーター。 */
  const params = new SearchCourseParams(
    KOUZOJI_STATION_LANDMARK,
    arrCoordinate,
    depDate,
    corporations
  );
  const courses = await getSearchCourse(params);
  /** 最初の地点と区間を取り除くなど整形後の経路。 */
  const modified = courses.map((course) =>
    modifyCourse(course, arrivalPointName)
  );
  /** 最初の区間でバスを利用する経路。 */
  const filtered = filterCourses(modified, depDate);
  /** 出発日時の早い順にソートした経路。 */
  const sorted = sortByDepartureTime(filtered);
  return sorted;
};
