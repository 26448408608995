import { Typography } from '@mui/material';

interface Props {
  name: string;
  subLabel: string;
}

const LineName: React.FC<Props> = (props: Props) => {
  return (
    <>
      <Typography sx={{ color: '#555555' }}>{props.name}</Typography>
      <Typography sx={{ color: '#555555' }}>{props.subLabel}</Typography>
    </>
  );
};

export default LineName;
