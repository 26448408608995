/**
 * 矢印を黄色で表示するかを判定する。
 * @param busStopCode バス停コード（URLパラメーター上で指定される値）。
 * @param busRideSpot のりば番号。
 * @param corpName 会社名。
 * @param lineName バスの系統名。
 * @returns 矢印を黄色で表示するかのフラグ。
 */
export function isYellow(
  busStopCode: string,
  busRideSpot: string,
  corpName: string,
  lineName: string
) {
  // 納屋橋・広小路本町バス停それぞれの表示条件どちらか一方を満たす場合、黄色の矢印を表示する。
  return (
    (isNayabashi(busStopCode, busRideSpot) &&
      isTargetBusNayabashi(lineName, corpName)) ||
    (isHirokoji(busStopCode, busRideSpot) && isTargetLineAtHirokoji(lineName))
  );
}

/**
 * 納屋橋バス停で黄色で表示するバス路線であるかを判定する。
 * @param lineName 路線名。
 * @param corpName 会社名。
 * @returns 納屋橋バス停で黄色で表示するバス路線であるかを判定した結果。
 */
function isTargetBusNayabashi(lineName: string, corpName: string) {
  return isTargetLineAtNayabashi(lineName) || isMeitetsu(corpName);
}

/**
 * 黄色で表示する対象のバス停ののりばであるかを判定する。
 * @param busStopCode バス停コード。
 * @param busRideSpot のりば番号。
 * @returns 対象のバス停ののりばであるかを判定した結果。
 */
export function isHirokoji(busStopCode: string, busRideSpot: string) {
  return busStopCode === 'hirokojihonmachi_7' && busRideSpot === '7';
}

/**
 * 納屋橋バス停で黄色で表示する対象のバス停ののりばであるかを判定する。
 * @param busStopCode バス停コード。
 * @param busRideSpot のりば番号。
 * @returns 対象のバス停ののりばであるかを判定した結果。
 */
export function isNayabashi(busStopCode: string, busRideSpot: string) {
  return busStopCode === 'nayabashi_2' && busRideSpot === '2';
}

/**
 * 納屋橋バス停で黄色で表示する対象のバス系統であるかを判定する。
 * @param lineName バスの系統名。
 * @param corpName 会社名。
 * @returns 対象のバス系統であるかを判定した結果。
 */
export function isTargetLineAtNayabashi(lineName: string) {
  const names = ['名駅１６', '幹栄２', '栄２４', 'Ｃ－７５８', '栄２２'];
  return names.includes(lineName);
}

/**
 * 広小路本町バス停で黄色で表示する対象のバス系統であるかを判定する。
 * @param lineName バスの系統名。
 * @returns 対象のバス系統であるかを判定した結果。
 */
export function isTargetLineAtHirokoji(lineName: string) {
  const names = ['Ｃ－７５８'];
  return names.includes(lineName);
}

/**
 * 会社名から名鉄バスであるかを判定する。
 * @param corpName 会社名。
 * @returns 名鉄バスであるかを判定した結果。
 */
export function isMeitetsu(corpName: string) {
  return corpName.includes('名鉄バス');
}
