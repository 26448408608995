import json from './north-bus-poles.json';

type Pole = {
  /** のりばまでの徒歩時間。 */
  walking_time: number;
  /** 最寄りの階段コード。 */
  stair_code: string;
};

type NorthBusPoleType = {
  [key: string]: Pole;
};

/** 高蔵寺駅北口ののりば情報。 */
export const northBusPoles: NorthBusPoleType = json;
