import React, { useState } from 'react';
import {
  AppState,
  useAppState,
  useSetAppState,
} from '../contexts/AppStateContext';
import {
  getArrPoint,
  getBusStopCode,
  getDepDate,
  getErrorMessages,
  getParams,
} from '../utils/params';
import Result from './Result';
import ErrorCourses from './Course/ErrorCourses';
import { searchCourse } from '../utils/api/search-course/nagoya-city';
import DummyCourse01 from '../utils/api/dummy/dummyCourse01.json';
import DummyCourse02 from '../utils/api/dummy/dummyCourse02.json';
import DummyCourse03 from '../utils/api/dummy/dummyCourse03.json';
import DummyCourse04 from '../utils/api/dummy/dummyCourse04.json';
import DummyCourse05 from '../utils/api/dummy/dummyCourse05.json';

import { getCourseColors } from '../utils/nagoya-city';
import { ERROR_STATUS, SearchError } from '../utils/error';
import NotFound from './NotFound';
import styled from '@emotion/styled';
import { modifyCourse } from '../utils/course';
import LoadingCircle from './LoadingCircle';
import { BUS_STOP_CODES } from '../utils/api/search-course/nagoya-city/bus-stop-codes';
import { isValidKey } from '../utils/environment';
import { sendErrorLog } from '../utils/sendLogs';

interface DummyCourse {
  course: any;
  arrivalPointName: string;
}

const DummyCourses: { [key: string]: DummyCourse } = {
  dummy01: {
    course: DummyCourse01.ResultSet.Course[0],
    arrivalPointName: '名古屋フェリー埠頭',
  },
  dummy02: {
    course: DummyCourse02.ResultSet.Course[0],
    arrivalPointName: '喫茶マウンテン',
  },
  dummy03: {
    course: DummyCourse03.ResultSet.Course[0],
    arrivalPointName: '体感！しだみ古墳群ミュージアム',
  },
  dummy04: {
    course: DummyCourse04.ResultSet.Course[0],
    arrivalPointName: '五色園あおぞら公園',
  },
  /** 名鉄バスを使う例 */
  dummy05: {
    course: DummyCourse05.ResultSet.Course[0],
    arrivalPointName: '矢場町',
  },
};

/** ダミー経路を表示するデモモードか */
const isDemoMode = process.env['REACT_APP_DEMO_MODE'] === 'true' || false;

/** 画面全体のレイアウト */
const MainScreen = styled('div')({});

const Main: React.FC = () => {
  const state = useAppState();
  /** アプリケーション全体の状態を更新する関数。 */
  const setState = useSetAppState();
  /** URLパラメーター。 */
  const params = new URLSearchParams(location.search);
  /** 目的地。 */
  const arrPoint = getArrPoint(params);
  /** バス停コード。 */
  const busStopCode = getBusStopCode(params);
  /** 出発日時。 */
  const depDate = getDepDate(params);
  /** debugパラメーター。 */
  const debug = getParams(params, 'debug');
  /** keyパラメーター。 */
  const key = getParams(params, 'key');

  /** アクセスを許可するか */
  const [allowAccess, switchAllowAccess] = useState(true);
  /** パラメーターに関するエラーがあるかを判定した結果。 */
  const [hasInvalidParams, switchInvalidParams] = useState(false);

  const isError = (state: AppState) => {
    return state.isError || state.courses.length === 0;
  };
  const isShowResultCourse = () => {
    return !state.isLoading && allowAccess && !isError(state);
  };
  const isShowErrorCourse = () => {
    return !state.isLoading && allowAccess && isError(state);
  };

  let initCall = true;

  /** 初期読み込み時にクエリより経路検索を実行する */
  React.useEffect(() => {
    (async () => {
      /**
       * useEffectで2回呼ばれてしまうため、対処療法的に初回のみ検索をかける
       * 初回読み込み判定変数をuseStateで取り扱うとタイミング的にうまく発火しないので通常変数を利用する
       */
      if (!initCall) {
        return;
      }
      initCall = false;
      try {
        /** パラメーターに関するエラーメッセージの配列。 */
        const paramsErrorMessages = getErrorMessages(params);
        /** パラメーターに関してエラーがあるかを判定した結果。 */
        // エラーメッセージが1件以上存在する場合をエラーがあるとみなす。
        const currentHasInvalidParams = paramsErrorMessages.length > 0;
        switchInvalidParams(currentHasInvalidParams);

        // パラメーターに関するエラーがある場合。
        if (currentHasInvalidParams) {
          // デモモードの場合。
          if (isDemoMode) {
            // デモ用のパラメーターを設定する。
            params.set('arrivalPointName', '大曽根');
            params.set('busStopCode', BUS_STOP_CODES.hirokojihonmachi_7);
          } else {
            // デモモードではない、かつ、debugパラメーターが空の場合、経路なし画面を表示する。
            if (debug === '') {
              throw new SearchError(paramsErrorMessages[0]);
            }
          }
        }
        /**
         * 以下どちらかの条件を満たす場合にはダミー経路を表示する
         * 1. 「__dummy__=XXX」と何かしらの文字列がクエリに設定された場合
         * 2. Reactの環境変数「REACT_APP_DEMO_MODE: true」かつ、クエリに不備がある場合
         * 3. debug=showDetailCoursesの時には実際に検索した上で全ての経路情報を出力する
         */
        if (
          debug.includes('dummy') ||
          (isDemoMode && currentHasInvalidParams)
        ) {
          /** 各種エラーチェックテスト */
          if (debug === 'dummyNoRoute') {
            throw new SearchError(ERROR_STATUS.NO_ROUTE);
          }
          console.log(DummyCourses[debug]);
          const dummyCourse: any = DummyCourses[debug] || DummyCourses.dummy01;
          params.set('arrivalPointName', dummyCourse.arrivalPointName);
          const colors = getCourseColors(dummyCourse.course, busStopCode);
          setState({
            ...state,
            isLoading: false,
            isError: false,
            isDummyCourse: true,
            courses: [dummyCourse.course].map((course) =>
              modifyCourse(course, dummyCourse.arrivalPointName)
            ),
            color: colors,
          });
          return;
        }
        /** デモ表示モードではない時にはKeyのチェックを行う */
        const tmpAllowAccess = isValidKey(key);
        switchAllowAccess(tmpAllowAccess);
        /** Keyが間違っている時には経路検索を行わない */
        if (!tmpAllowAccess) {
          setState({
            ...state,
            isLoading: false,
          });
          return;
        }
        setState({
          ...state,
          isLoading: true,
        });
        /** 経路検索を実行する */
        const courses = await searchCourse(
          depDate,
          arrPoint.location,
          busStopCode,
          arrPoint.name
        );
        // 経路がない場合。
        if (courses.length === 0) {
          throw new SearchError(ERROR_STATUS.NO_ROUTE);
        }
        const colors = getCourseColors(courses[0], busStopCode);
        setState({
          ...state,
          isLoading: false,
          courses: courses,
          color: colors,
        });
      } catch (e: any) {
        const errorStatus: ERROR_STATUS = await (async () => {
          if (!(e instanceof SearchError)) {
            await sendErrorLog(e, ERROR_STATUS.UNEXPECTED_ERROR);
            return ERROR_STATUS.UNEXPECTED_ERROR;
          }
          switch (e.message) {
            case ERROR_STATUS.NO_ROUTE:
            case ERROR_STATUS.INVALID_QUERY:
            case ERROR_STATUS.INVALID_ARRIVAL_POINT:
            case ERROR_STATUS.NO_SET_BUS_STOP_CODE:
            case ERROR_STATUS.MIXWAY_API_ERROR:
              return e.message;
            default:
              await sendErrorLog(e, ERROR_STATUS.UNEXPECTED_ERROR);
              return ERROR_STATUS.UNEXPECTED_ERROR;
          }
        })();
        // エラーが起きた場合には isError をtrueにする
        setState({
          ...state,
          isLoading: false,
          isError: true,
          errorStatus: errorStatus,
        });
      }
    })();
  }, []);

  return (
    <MainScreen>
      {/** 経路を検索中の場合 */}
      {state.isLoading && <LoadingCircle />}
      {/** エラーの場合 */}
      {isShowErrorCourse() && <ErrorCourses />}
      {/** 経路を表示可能な場合 */}
      {isShowResultCourse() && (
        <Result
          hasInvalidParams={hasInvalidParams}
          busStopCode={busStopCode}
          depDate={depDate}
          arrivalPointName={arrPoint.name}
          arrivalPointPosition={arrPoint.location}
        />
      )}
      {/** ACCESSが許可されていない場合 */}
      {!allowAccess && <NotFound />}
    </MainScreen>
  );
};

export default Main;
