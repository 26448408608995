import { ListItemButton, Divider, Grid, Typography } from '@mui/material';
import { RailLine } from '../../../types/mixway-api/rail';
import styled from '@emotion/styled';
import { toHHmm } from '../../../utils/datetime';

interface Props {
  line: RailLine;
  isNorth: boolean;
}

export const ListItem: React.FC<Props> = (props: Props) => {
  const BodyGrid = styled(Grid)({
    textAlign: 'center',
  });

  const NextBusLabel = styled(Typography)({
    fontSize: '1.25rem',
  });

  const depTime = toHHmm(props.line.DepartureState.Datetime.text);
  const busPoleNumber = props.line.DepartureState.no
    ? props.line.DepartureState.no
    : '';

  const selected = props.line.selected === 'true';

  const ButtonColorStyle = {
    background: selected ? '#BBBBBB' : '#FFFFFF',
  };

  /** 駅の出口（北口 or 南口）のラベル。 */
  const gate = props.isNorth ? '北口' : '南口';

  return (
    <>
      <ListItemButton style={ButtonColorStyle}>
        <Grid container>
          <BodyGrid item xs={2}>
            <NextBusLabel>{depTime}発</NextBusLabel>
          </BodyGrid>
          <BodyGrid item xs={6}>
            <NextBusLabel>{props.line.Name}</NextBusLabel>
          </BodyGrid>
          <BodyGrid item xs={4}>
            {busPoleNumber !== '' && (
              <NextBusLabel>
                {gate}
                {props.line.DepartureState.no}番のりば
              </NextBusLabel>
            )}
          </BodyGrid>
        </Grid>
      </ListItemButton>
      <Divider />
    </>
  );
};
