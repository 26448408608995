import { DateTime } from 'luxon';

/**
 * ISO8601形式の日時をHH:mm形式の時刻（JST)にする。
 * @param text ISO8601形式の日時の文字列。
 * @returns HH:mm形式の時刻（JST)。
 */
export function toHHmm(text: string) {
  return toJSTDatetime(text).toFormat('HH:mm');
}

/**
 * ISO8601形式の日時をluxonの日時データ（JST)にする。
 * @param text ISO8601形式の日時の文字列。
 * @returns luxonの日時データ（JST)。
 */
export function toJSTDatetime(text: string) {
  return DateTime.fromISO(text, { zone: 'Asia/Tokyo', setZone: true });
}
