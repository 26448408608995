import { useSearchParams } from 'react-router-dom';
import BUS_STOP_POSITIONS from '../../data/departureBusstopPositionList.json';
import { debugLog } from '../logeer';

const busStopCodes: { [key: string]: string } = BUS_STOP_POSITIONS;

const BUS_STOP_NAMES: { [key: string]: string } = {
  hirokojihonmachi_7: '広小路本町（7番）',
  nayabashi_2: '納屋橋（2番）バス停',
  kozoji: '高蔵寺バス停',
};

/** mixway webへのリンクURLを取得する。 */
export function getMixwayURL() {
  const [searchParams] = useSearchParams();
  const busStopCode = searchParams.get('busStopCode');
  const arrivalPosition = searchParams.get('arrivalPosition');
  const arrivalPointName = searchParams.get('arrivalPointName');
  const departureDate = searchParams.get('departureDate');
  const departureTime = searchParams.get('departureTime');
  if (
    busStopCode === null ||
    arrivalPosition === null ||
    arrivalPointName === null ||
    departureDate === null ||
    departureTime === null
  ) {
    return 'https://mixway.ekispert.net/';
  }
  const depStationCode = busStopCodes[busStopCode] || '';
  /** 出発バス停の名称。 */
  const busStopName = BUS_STOP_NAMES[busStopCode] || '';
  const params = new URLSearchParams({
    viaList: `${depStationCode}:${arrivalPosition}`,
    fromName: busStopName,
    toName: arrivalPointName,
    date: departureDate,
    time: departureTime,
    searchType: 'departure',
  });
  debugLog(
    `QRコードリンク:\nhttps://mixway.ekispert.net/courses?${params.toString()}`
  );
  return `https://mixway.ekispert.net/courses?${params.toString()}`;
}
