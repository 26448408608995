import { getSearchCourse } from '../../mixway-api';
import LANDMARKS from '../../../../data/landmarks.json';
import NEAR_BUSSTOP_INFOMATION from '../../../../data/nearBusStopInfo.json';
import { SearchCourseParams } from '../params';
import { ERROR_STATUS, SearchError } from '../../../error';
import { DateTime } from 'luxon';
import { NearBusStopInfoType } from '../../../../components/Header/BusRideSpot';
import { isNumber } from '../../../../components/ArrivalGuide/utils';
import { modifyCourse, sortByDepartureTime } from '../../../course';
import { filterCourses } from '../../../nagoya-city';

const landmarks: { [key: string]: string } = LANDMARKS;
const nearBusStopInfo: NearBusStopInfoType = NEAR_BUSSTOP_INFOMATION;

/**
 * サイネージ位置からバス停までの標準移動時刻（＝バス停までの所要時間）を取得する。
 * @returns サイネージ位置からバス停までの標準移動時刻。
 */
export const getDefaultWalkingTime = () => {
  const waitTime = process.env['REACT_APP_SEARCH_WAIT_TIME'];
  // 環境変数でバス停までの所要時間が未設定、または数値ではない場合は3分を返す。
  if (waitTime === undefined || !isNumber(waitTime)) {
    return 3;
  }
  return Number(waitTime);
};

/**
 * 経路検索対象の会社名を取得する。
 * @returns 「:」つなぎの会社名。
 */
export const getCorporations = () => {
  /** 会社名の配列。 */
  const corporations = ['名古屋市営バス', '名鉄バス'];
  return corporations.join(':');
};

/**
 * バス停の各のりばまでの所要時間の中で最小の時間を取得する。
 * @param busStopCode バス停コード。
 * @returns バス停の各のりばまでの所要時間の中で最小の時間。
 */
export const getMinWalkTime = (busStopCode: string) => {
  // クエリの段階でバス停情報は分かるので、バス停ごとのサイネージ->乗り場までの最小所要時間を検索時刻とする
  if (busStopCode === '') {
    return 0;
  }
  /** のりば番号（キー）。 */
  const keys = Object.keys(nearBusStopInfo[busStopCode]);
  /** 各のりばまでの所要時間の配列。 */
  const walkTimes = keys.map(
    (key) => nearBusStopInfo[busStopCode][key]?.walkTime
  );
  // 最小の値を返却する。
  return Math.min(...walkTimes);
};

/**
 * 名古屋市案件用の経路を取得する。
 * @param date 出発日時。
 * @param arrCoordinate 目的地の緯度経度。
 * @param busStopCode 出発バス停のコード。
 * @param arrivalPointName 目的地の名称。
 * @returns 名古屋市案件用の経路。
 */
export const searchCourse = async (
  date: DateTime,
  arrCoordinate: string,
  busStopCode: string,
  arrivalPointName: string
) => {
  const depStationCode = busStopCode && landmarks[busStopCode];
  if (depStationCode === null) {
    throw new SearchError(ERROR_STATUS.NO_SET_BUS_STOP_CODE);
  }
  /** 各バス停までの所要時間の中で、最小の所要時間。 */
  const minWalkTime = getMinWalkTime(busStopCode);
  /** 検索時刻を一律でプラスX分する場合には以下のコメントを外す */
  // const depDate = getAddSearchWaitTimeDepDate(query.depDate);
  /** 検索時刻はクエリで与えられた出発時刻+バス停までの最小所要時間とする */
  const depDate = date.plus({
    minutes: minWalkTime,
  });
  /** 名古屋市の実証実験で経路検索の対象とする交通事業者の指定。 */
  const corporations = getCorporations();
  /** 経路検索のパラメーター。 */
  const params = new SearchCourseParams(
    depStationCode,
    arrCoordinate,
    depDate,
    corporations
  );
  const courses = await getSearchCourse(params);
  /** 最初の地点と区間を取り除くなど整形後の経路。 */
  const modified = courses.map((course) =>
    modifyCourse(course, arrivalPointName)
  );
  /** 最初の区間でバスを利用する経路。 */
  const filtered = filterCourses(modified, busStopCode, depDate);
  /** 出発日時の早い順にソートした経路。 */
  const sorted = sortByDepartureTime(filtered);
  return sorted;
};
