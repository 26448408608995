import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { AppStateProvider } from './contexts/AppStateContext';
import NotFound from './components/NotFound';
import Root from './components/Root';
import ErrorBoundary from './components/ErrorBoundary';

function App() {
  return (
    <div className="App">
      <AppStateProvider>
        <ErrorBoundary>
          <BrowserRouter>
            <Routes>
              <Route index path="/" element={<Root />} />
              <Route index path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </ErrorBoundary>
      </AppStateProvider>
    </div>
  );
}

export default App;
