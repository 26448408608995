import React from 'react';
import { Grid, Typography, styled } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { getMoveKasugaiUrl } from '../../../utils/qr/kozoji';
import { Coordinate } from '../../../utils/coordinate';

/**
 * 高蔵寺用QRコード部分のコンポーネント。
 */
interface Props {
  /** 目的地の緯度経度。 */
  arrivalPointPosition: string;
}

const KozojiQRCodeArea: React.FC<Props> = (props: Props) => {
  const QRCodeAreaGrid = styled(Grid)({
    marginTop: 20,
    background: '#FFFFFF',
  });

  /** 緯度経度。 */
  const coordinate = new Coordinate(props.arrivalPointPosition);
  /** moveかすがいのURL。 */
  const moveKasugaiUrl = getMoveKasugaiUrl(coordinate);

  return (
    <QRCodeAreaGrid container textAlign="center">
      <Grid item xs={6}>
        {moveKasugaiUrl !== null && (
          <>
            <Typography variant="body2" style={{ marginBottom: 10 }}>
              さらに詳しい経路はこちら
            </Typography>
            {/* moveかすがいのQRコード。 */}
            <QRCodeSVG value={moveKasugaiUrl} size={100} />
          </>
        )}
      </Grid>
      <Grid item xs={6}></Grid>
    </QRCodeAreaGrid>
  );
};

export default KozojiQRCodeArea;
