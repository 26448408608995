export enum ERROR_STATUS {
  /** 経路が見つからない */
  'NO_ROUTE' = 'NO_ROUTE',
  /** 入力値が異常 */
  'INVALID_QUERY' = 'INVALID_QUERY',
  /** 到着地点座標が異常 */
  'INVALID_ARRIVAL_POINT' = 'INVALID_ARRIVAL_POINT',
  /** バス停コードが指定されていない */
  'NO_SET_BUS_STOP_CODE' = 'NO_SET_BUS_STOP_CODE',
  /** Mixawy API の呼び出しエラー */
  'MIXWAY_API_ERROR' = 'MIXWAY_API_ERROR',
  /** 予想外のエラー */
  'UNEXPECTED_ERROR' = 'UNEXPECTED_ERROR',
}

/**
 * enumで定義されたエラーメッセージを日本語で返す
 * @param errorStatus
 */
export const getErrorMessage = (errorStatus: ERROR_STATUS): string[] => {
  switch (errorStatus) {
    case ERROR_STATUS.NO_ROUTE:
      return ['バス・鉄道を利用した経路が', '見つかりませんでした'];
    case ERROR_STATUS.INVALID_QUERY:
      return ['入力値が異常です'];
    case ERROR_STATUS.INVALID_ARRIVAL_POINT:
      return ['到着地点座標の指定が間違っています'];
    case ERROR_STATUS.NO_SET_BUS_STOP_CODE:
      return ['出発バス停の指定が間違っています'];
    case ERROR_STATUS.MIXWAY_API_ERROR:
      return ['経路検索中にエラーが発生しました'];
    case ERROR_STATUS.UNEXPECTED_ERROR:
      return ['想定外のエラーが発生しました'];
  }
};

export class SearchError extends Error {
  constructor(errorType: ERROR_STATUS) {
    super(errorType);
    this.name = 'SearchError';
  }
}
