import { Grid } from '@mui/material';
import ArrivalGuide from '../ArrivalGuide';
import LineName from './LineName';
import {
  getBusSystemNumber,
  getDestination,
  LINE_TYPE,
} from '../../utils/api/mixway-utils';
import TrafficType from './TrafficType';
import { isDisplayArrivalGuide } from '../ArrivalGuide/utils';
import { getDestinationLabel } from '../../utils/course/line';

interface Props {
  lineType: LINE_TYPE;
  stopStationCount: string;
  lineColor: string;
  name: string;
  destination?: string;
  typicalName?: string;
}

/** 区間1個分のコンポーネント。 */
const Line: React.FC<Props> = (props: Props) => {
  const busSystemNumber = props.typicalName
    ? getBusSystemNumber(props.typicalName)
    : '';
  const destination = props.destination
    ? getDestinationLabel(getDestination(props.destination))
    : '';
  const subLabel = () => {
    switch (props.lineType) {
      case LINE_TYPE.train:
        // 列車の場合のみ行き先を表示する
        return props.destination ? destination : '';
      case LINE_TYPE.bus:
        if (busSystemNumber !== '' && destination) {
          return `[${busSystemNumber}]${destination}`;
        }
        if (busSystemNumber !== '') {
          return `[${busSystemNumber}]`;
        }
        if (destination !== '') {
          return `${destination}`;
        }
        // バスの場合系統番号を表示する
        return '';
      default:
        return '';
    }
  };
  /** 区間を表す線の色。 */
  const borderColor =
    props.lineType === LINE_TYPE.walk ? props.lineColor : '#6b8193';
  /** 区間を表す線の種類。 */
  const borderStyle = props.lineType === LINE_TYPE.walk ? 'dashed' : 'solid';
  return (
    <Grid container alignItems="stretch" columnSpacing={2}>
      {/** アイコン表示 */}
      <Grid item xs={1}>
        <div
          style={{
            borderLeft: `8px ${borderStyle} ${borderColor}`,
            height: '100%',
            top: 0,
            marginLeft: '20px',
          }}
        />
      </Grid>
      <Grid item xs={1} alignSelf="center">
        <TrafficType type={props.lineType} />
      </Grid>
      {/** 路線名・徒歩の場合乗り換え時間表示 */}
      <Grid item xs={4} alignSelf="center" sx={{ padding: 2 }}>
        <LineName name={props.name} subLabel={subLabel()} />
      </Grid>
      {/** 下車バス停・駅の案内 */}
      <Grid item xs={6} alignSelf="center">
        {isDisplayArrivalGuide(props.lineType) && (
          <ArrivalGuide
            lineType={props.lineType}
            stopStationCount={props.stopStationCount}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Line;
