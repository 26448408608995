import { Box, Grid, styled } from '@mui/material';
import React from 'react';

interface Props {
  /** 駅の「北口」または「南口」 */
  exitDirection: string;
  /** ポール番号 */
  busRideSpot: string;
}

/**
 * バス乗り場部分のコンポーネント。
 */
const DepartureBusStop: React.FC<Props> = (props: Props) => {
  /** 北口か南口を表示するスタイル */
  const ExitDirectionSpan = styled('span')({
    fontSize: '60px',
    lineHeight: '65px',
  });
  /** 何番乗り場かを表示するスタイル */
  const BusRideSpotSpan = styled('span')({
    fontSize: '60px',
    lineHeight: '65px',
  });

  return (
    <Box
      border={3}
      sx={{
        margin: '10px',
        height: '125px',
        width: '130px',
      }}
    >
      <Grid container direction="column" justifyContent="center">
        <Grid item xs>
          <ExitDirectionSpan>{props.exitDirection}</ExitDirectionSpan>
        </Grid>
        {props.busRideSpot !== '' && (
          <Grid item xs>
            <BusRideSpotSpan>{props.busRideSpot}</BusRideSpotSpan>番のりば
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default DepartureBusStop;
