import { AppColors, initAppColors } from '../contexts/AppStateContext';
import { Course, Line, Point } from '../types/mixway-api/extreme';
import {
  LINE_TYPE,
  getBusStopPoleInfo,
  getBusSystemNumber,
  getLineType,
} from './api/mixway-utils';
import { NearBusStopInfoType } from '../components/Header/BusRideSpot';
import nearBusStopInfo from '../data/nearBusStopInfo.json';
import { getDefaultWalkingTime } from './api/search-course/nagoya-city';
import { DateTime } from 'luxon';
import { debugLog } from './logeer';
import { BUS_STOP_CODES } from './api/search-course/nagoya-city/bus-stop-codes';

const NEAR_BUSSTOP_INFOMATION: NearBusStopInfoType = nearBusStopInfo;

/**
 * のりば情報を取得する。
 * @param busStopCode のりば番号。
 * @param firstLine 経路の最初の区間。
 * @returns のりば情報。
 */
export const getBusPole = (busStopCode: string, firstLine: Line) => {
  // バス停コードが未設定の場合はundefinedを返す。
  if (busStopCode === '') {
    return undefined;
  }
  /** のりば番号。 */
  const poleNumber = getBusStopPoleInfo(firstLine, busStopCode);
  /** のりば情報。 */
  const busPole = NEAR_BUSSTOP_INFOMATION[busStopCode][poleNumber];
  debugLog(`${firstLine.Name}`);
  // のりば情報が参照できた場合のみログを出力する。
  if (busPole) {
    debugLog(`乗り場情報: ${busPole}`);
    debugLog(`サイネージ位置→バス停徒歩時刻: ${busPole.walkTime}分`);
  }
  return busPole;
};

/**
 * 該当の経路が条件に合致しているかを確認する
 * @param firstLine 最初の区間。
 * @param depDate 出発日時。
 * @returns 経路が条件に合致しているか判定した結果。
 */
export const isMatchCourse = (
  firstLine: Line,
  busStopCode: string,
  depDate: DateTime
) => {
  const firstLineType = getLineType(firstLine.Type);
  if (firstLineType !== LINE_TYPE.bus) {
    debugLog('2区間目が路線バスでないため弾かれました');
    return false;
  }
  /** バス出発時刻 */
  const busDepDate = DateTime.fromISO(firstLine.DepartureState.Datetime.text);
  /** バス停までの所要時間（単位:[分]）。 */
  const requiredTimeToBusStop = getDefaultWalkingTime();
  /** バスポール情報。 */
  const busPole = getBusPole(busStopCode, firstLine);
  /** サイネージからの所要時間。 */
  // バスのりば情報が取得できなかった場合は、一律で設定した所要時間とする。
  const requiredTime = busPole ? busPole.walkTime : requiredTimeToBusStop;
  /** 経路ごとに許容できる出発時刻 */
  const deadlineDate = depDate.plus({ minutes: requiredTime });
  debugLog(`出発許容時刻: ${deadlineDate.toString()}`);
  debugLog(`バス出発時刻: ${busDepDate.toString()}`);
  debugLog(`時刻表示判定: ${(deadlineDate <= busDepDate).toString()}`);
  /** バス出発時刻が、現在時刻+バス停までの移動時間よりも後の経路のみ表示する */
  return deadlineDate <= busDepDate;
};

/**
 * 条件にマッチする経路だけを抽出する
 * @param courses 取得した経路。
 * @param busStopCode バス停コード。
 * @param depDate 出発日時。
 * @returns
 */
export const filterCourses = (
  courses: Course[],
  busStopCode: string,
  depDate: DateTime
): Course[] => {
  debugLog(courses);
  // 最初の区間がバス利用となる経路を残す。
  /** フィルター後の経路。 */
  const filtered = courses.filter((course, idx) => {
    debugLog(`========= 経路${idx} =========`);
    return isMatchCourse(course.Route.Line[0], busStopCode, depDate);
  });
  debugLog(`条件にマッチした経路が${filtered.length}件あります`);
  debugLog('----------検索終了----------');
  if (filtered.length === 0) {
    return [];
  }
  return filtered;
};

/** 路線ごとのカラーリング一覧 */
const ColorTypeSet = {
  /** （名駅16）2番のりば 名古屋駅 水色（#3396B8） */
  meieki_16: {
    header: '#3396B8',
    arrow: '#3396B8',
    font: '#FFFFFF',
  },
  /** （幹栄2）2番のりば 新大正橋西(笹島町経由) 橙（ #FF9D01） */
  kansakae2: {
    header: '#FF9D01',
    arrow: '#FF9D01',
    font: '#FFFFFF',
  },
  /**（栄24）2番のりば  稲西車庫(笹島町経由) 緑（#00801A） */
  sakae24: {
    header: '#00801A',
    arrow: '#00801A',
    font: '#FFFFFF',
  },
  /**（C‐758）2番のりば  名古屋 赤紫（#CC0066) */
  c758: {
    header: '#CC0066',
    arrow: '#CC0066',
    font: '#FFFFFF',
  },
  /**（栄22）2番のりば  港区役所(尾頭橋経由) ピンク（ #FF99CC) */
  sakae22: {
    header: '#FF99CC',
    arrow: '#FF99CC',
    font: '#FFFFFF',
  },
  /** 名鉄 赤（#FF0000) */
  meitetsu: {
    header: '#FF0000',
    arrow: '#FF0000',
    font: '#FFFFFF',
  },
};

export const getCourseColors = (
  course: Course | null,
  busStopCode: string
): AppColors => {
  if (course === null || busStopCode === null) {
    return initAppColors;
  }
  /** 最初の区間を取得する */
  const firstLine = course.Route.Line[0];
  const busSystemNumber = getBusSystemNumber(firstLine.TypicalName || '');
  /** のりばを取得する */
  const busStopPole = getBusStopPoleInfo(firstLine, busStopCode);

  if (busStopCode === BUS_STOP_CODES.nayabashi_2) {
    /** 1番のりばの場合 */
    if (busStopPole !== '2') {
      return initAppColors;
    }
    /** 名鉄バスの場合 */
    if (firstLine.TypicalName?.includes('名鉄バス')) {
      return ColorTypeSet.meitetsu;
    }
    /** 納屋橋 */
    switch (busSystemNumber) {
      case '名駅１６':
        return ColorTypeSet.meieki_16;
      case '幹栄２':
        return ColorTypeSet.kansakae2;
      case 'Ｃ－７５８':
        return ColorTypeSet.c758;
      case '栄２４':
        return ColorTypeSet.sakae24;
      case '栄２２':
        return ColorTypeSet.sakae22;
      default:
        return initAppColors;
    }
  } else {
    /** 広小路本町 */
    if (
      busSystemNumber === 'Ｃ－７５８' &&
      (busStopPole === '7' || busStopPole === '2')
    ) {
      return ColorTypeSet.c758;
    } else {
      return initAppColors;
    }
  }
};

/**
 * 広小路本町2番のりばを7番のりばに置き換える必要があるか
 * @param line
 * @param point
 */
export const isReplacementPlatform7 = (line: Line, point: Point) => {
  if (!point.Station || point?.Station?.code === undefined) {
    return false;
  }
  /** 最初のポイントが広小路本町バス停であるか */
  if (point.Station.code !== '40904') {
    return false;
  }
  /** 系統を取得する */
  const busSystemNumber = getBusSystemNumber(line.TypicalName || '');
  /** のりばを取得する */
  const busStopPole = getBusStopPoleInfo(
    line,
    BUS_STOP_CODES.hirokojihonmachi_7
  );
  /** ループバス（Ｃ－７５８）かつ2番のりばであるか */
  return busSystemNumber === 'Ｃ－７５８' && busStopPole === '2';
};
