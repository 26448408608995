import { DateTime } from 'luxon';
import Course from '../Course';
import Header from '../Header';
import Map from './Map';
import KozojiQRCodeArea from './QR';
import ErrorCourses from '../Course/ErrorCourses';
import { sendErrorLog } from '../../utils/sendLogs';
import { ERROR_STATUS } from '../../utils/error';
import { useAppState } from '../../contexts/AppStateContext';

interface Props {
  /** 出発日時。 */
  depDate: DateTime;
  /** 目的地の緯度経度。 */
  arrivalPosition: string;
  /** URLパラメーターで指定されたバス停コード。 */
  busStopCode: string;
}

/** 経路を取得できた場合に表示する部分のコンポーネント。 */
const Result: React.FC<Props> = (props: Props) => {
  const state = useAppState();
  try {
    return (
      <>
        <Header
          depDate={props.depDate}
          arrivalPointPosition={props.arrivalPosition}
          busStopCode={props.busStopCode}
        />
        <Map />
        <Course />
        <KozojiQRCodeArea arrivalPointPosition={props.arrivalPosition} />
      </>
    );
  } catch (e: any) {
    sendErrorLog(e, ERROR_STATUS.UNEXPECTED_ERROR);
    return <ErrorCourses isKozoji />;
  }
};

export default Result;
