import { Grid, styled, Typography } from '@mui/material';

interface Props {
  depDate?: string;
  arrDate?: string;
  label: string;
}

/** 地点用の文字のコンポーネント。 */
const PointTypography = styled(Typography)({
  fontSize: '1.25rem',
});

/** 地点1個分（経路部分の1行分）のコンポーネント。 */
const Point: React.FC<Props> = (props: Props) => {
  return (
    <Grid
      container
      alignItems="center"
      sx={{ padding: '10px', backgroundColor: '#D2D2D2', minHeight: '64px' }}
    >
      <Grid item xs={2}>
        {/* 到着日時。 */}
        {props.arrDate && <PointTypography>{props.arrDate} 着</PointTypography>}
        {/* 出発日時。 */}
        {props.depDate && <PointTypography>{props.depDate} 発</PointTypography>}
      </Grid>
      <Grid item xs={10}>
        <PointTypography>{props.label}</PointTypography>
      </Grid>
    </Grid>
  );
};

export default Point;
