import json from './south-bus-poles.json';

type Pole = {
  /** のりばまでの徒歩時間。 */
  walking_time: number;
};

type SouthBusPoleType = {
  [key: string]: Pole;
};

/** 高蔵寺駅南口のバスのりば情報。 */
export const southBusPoles: SouthBusPoleType = json;
