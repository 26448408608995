import React from 'react';
import Left from './svg/Left';
import Right from './svg/Right';
import RightDown from './svg/RightDown';
import RightUp from './svg/RightUp';

interface Props {
  /** 矢印内に表示するラベル。 */
  label: string;
  /** 矢印の方向。 */
  direction: string;
  /** 矢印を黄色にするかを指定するフラグ。 */
  yellow?: boolean;
}

export interface ArrowProps {
  /** 矢印内に表示するラベル。 */
  label: string;
  /** 矢印の色 */
  color: string;
  /** 矢印内の文字色 */
  textColor: string;
  /** 矢印の横幅 */
  width: number;
}

/** 矢印部分のコンポーネント。 */
const Arrow: React.FC<Props> = (props: Props) => {
  const color = props.yellow ? '#fffe41' : '#ebf0f0';
  const textColor = '#000000';
  const commonWidth = 220;
  const arrowProps: ArrowProps = {
    label: props.label,
    color,
    textColor,
    width: commonWidth,
  };
  switch (props.direction) {
    case 'RIGHT_UP':
      return <RightUp {...arrowProps} />;
    case 'RIGHT_DOWN':
      return <RightDown {...arrowProps} />;
    case 'RIGHT':
      return <Right {...arrowProps} />;
    case 'LEFT':
    default:
      return <Left {...arrowProps} />;
  }
};

export default Arrow;
