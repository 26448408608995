import { ArrowProps } from '..';

export const Left: React.FC<ArrowProps> = (props: ArrowProps) => {
  return (
    <svg
      width={props.width}
      viewBox="0 0 63.499999 15.874999"
      version="1.1"
      id="svg1"
    >
      <defs id="defs1">
        <linearGradient id="swatch1">
          <stop stopColor={props.color} stopOpacity={1} offset="0" id="stop1" />
        </linearGradient>
      </defs>
      <g id="layer1">
        <path
          id="rect1"
          fill={props.color}
          fillOpacity={1}
          stroke="#002060"
          strokeWidth={0.304377}
          strokeDasharray="none"
          strokeOpacity={1}
          d="M 8.3485789,0.52517393 8.3375895,4.3903749 H 63.28136 V 12.306917 H 8.3155989 L 8.3068154,15.44983 4.338679,12.224188 0.37054167,8.9985472 4.3595658,4.7618604 Z"
        />
        <text
          fontSize="4.5px"
          fill={props.color}
          fillOpacity={1}
          stroke={props.textColor}
          strokeWidth={0.264999}
          strokeDasharray="none"
          strokeOpacity={11}
          id="text1"
        >
          <tspan
            id="tspan1"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
            fontStretch="normal"
            fontSize="4.5px"
            fontFamily="Noto Sans Gothic"
            fill={props.textColor}
            stroke="none"
            strokeWidth={0.265}
            x="10"
            y="10"
          >
            {props.label}
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default Left;
