import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { Course } from '../types/mixway-api/extreme';
import { ERROR_STATUS } from '../utils/error';

export type AppState = {
  /** 経路検索取得中か */
  isLoading: boolean;
  /** エラーが発生しているか */
  isError: boolean;
  /** ダミー経路を表示しているか */
  isDummyCourse: boolean;
  /** 取得した経路。 */
  courses: Course[];
  /** 表示する経路のインデックス。 */
  courseIndex: number;
  /** エラー発生時のエラーステータス */
  errorStatus: ERROR_STATUS | null;
  /** 経路に応じたカラー */
  color: AppColors;
};

export interface AppColors {
  header: string;
  arrow: string;
  font: string;
}

export const initAppColors: AppColors = {
  header: '#313C3D',
  arrow: '#EBF0F0',
  font: '#FFFFFF',
};

const initialState: AppState = {
  isLoading: true,
  isError: false,
  errorStatus: null,
  isDummyCourse: false,
  courses: [],
  courseIndex: 0,
  color: initAppColors,
};

const AppStateContext = React.createContext<AppState>(initialState);

const SetAppStateContext = React.createContext<
  Dispatch<SetStateAction<AppState>>
>(() => {});

export function useAppState() {
  return useContext(AppStateContext);
}

export function useSetAppState() {
  return useContext(SetAppStateContext);
}

export function AppStateProvider(props: {
  initialState?: AppState;
  children: React.ReactNode;
}) {
  const [state, setState] = useState<AppState>(
    props.initialState ?? initialState
  );
  return (
    <AppStateContext.Provider value={state}>
      <SetAppStateContext.Provider value={setState}>
        {props.children}
      </SetAppStateContext.Provider>
    </AppStateContext.Provider>
  );
}
