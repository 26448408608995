import { Grid, styled } from '@mui/material';
import React from 'react';

interface Props {
  /** 高蔵寺駅の「南口」かどうか */
  isSouthExit: boolean;
  /** 方面（行き先） */
  destination: string;
  /** バス会社・路線名 */
  busLineName: string;
}

/**
 * 路線名・方面のコンポーネント。
 */
const Destination: React.FC<Props> = (props: Props) => {
  /** 行き先のスタイル */
  const DestinationSpan = styled('span')({
    fontSize: '32px',
    marginRight: '20px',
  });

  /** バス系統のスタイル */
  const BusNameSpan = styled('span')({
    fontSize: '18px',
    position: 'relative',
    marginRight: '20px',
  });

  /** ~行きのスタイル */
  const DirectionSpan = styled('span')({
    fontSize: '16px',
    position: 'relative',
  });

  return (
    <Grid container direction="row" alignItems="flex-end">
      <Grid item xs="auto">
        {/** バス会社の表示 */}
        {props.isSouthExit && <BusNameSpan>{props.busLineName}</BusNameSpan>}
        {/** バス行き先表示 */}
        <DestinationSpan>{props.destination}</DestinationSpan>
        <DirectionSpan>行き</DirectionSpan>
      </Grid>
    </Grid>
  );
};

export default Destination;
