import { ArrowProps } from '..';

/** 右上方向の矢印のコンポーネント。 */
export const RightUp: React.FC<ArrowProps> = (props: ArrowProps) => {
  return (
    <svg
      width={props.width}
      viewBox="0 0 63.499999 15.874999"
      version="1.1"
      id="svg1"
    >
      <defs id="defs1">
        <linearGradient id="swatch1">
          <stop stopColor="#fffe41" stopOpacity={1} offset="0" id="stop1" />
        </linearGradient>
      </defs>
      <g id="layer1">
        <path
          id="rect1"
          fill={props.color}
          fillOpacity={1}
          strokeWidth={0.346075}
          strokeDasharray="none"
          stroke="#002060"
          strokeOpacity={1}
          d="M 57.755985,0.79932834 55.212259,3.9807673 52.668535,7.1629532 h 2.74809 V 8.7869141 H 0.46566128 V 15.366421 H 55.416625 v 0.0052 h 5.077644 V 7.1629532 H 62.84395 L 60.300226,3.9807673 Z"
        />
        <text
          fontStyle="normal"
          fontVariant="normal"
          fontWeight="normal"
          fontStretch="normal"
          fontSize="4.5px"
          fontFamily="Noto Sans Gothic"
          fill={props.textColor}
          stroke="none"
          strokeWidth={0.264999}
          id="text1"
        >
          <tspan
            id="tspan1"
            fontSize="4.5px"
            fill={props.textColor}
            stroke="none"
            strokeWidth={0.265}
            x="3"
            y="14"
          >
            {props.label}
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default RightUp;
