import React, { ErrorInfo, ReactNode } from 'react';
import { useAppState } from '../contexts/AppStateContext';
import { sendErrorLog } from '../utils/sendLogs';
import { ERROR_STATUS } from '../utils/error';

type Props = {
  children: ReactNode;
};

type State = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(error: Error) {
    console.log('getDerivedStateFromError', error);
    return { hasError: true };
  }

  public componentDidCatch(error: Error) {
    sendErrorLog(error, ERROR_STATUS.UNEXPECTED_ERROR);
    return { hasError: true };
  }

  public render() {
    const { hasError } = this.state;
    const { children } = this.props;
    return hasError ? <h1>エラーが発生しました</h1> : children;
  }
}
export default ErrorBoundary;
