import React from 'react';
import { Grid, styled, Typography, Box } from '@mui/material';
import { useAppState } from '../../contexts/AppStateContext';
import { getErrorMessage } from '../../utils/error';
import { ERROR_STATUS } from '../../utils/error';
import { getMixwayURL } from '../../utils/qr/mixway';
import { QRCodeSVG } from 'qrcode.react';
import MoveKasugai from '../QR/MoveKasugai';

interface Props {
  /** 高蔵寺用の表示であるかを表すフラグ。 */
  isKozoji?: boolean;
}

/** 経路検索失敗時のエラーを表示するコンポーネント。*/
const ErrorCourses: React.FC<Props> = (props: Props) => {
  const state = useAppState();
  const mixwayUrl = getMixwayURL();

  const HeaderColor = styled(Grid)({
    background: state.color.header,
    color: '#FFFFFF',
    height: '50px',
    marginBottom: '30px',
  });

  const ResultStyle = styled('span')({
    display: '-webkit-inline-flex',
    textAlign: 'left',
    margin: '30px 40px 20px 40px',
  });

  return (
    <Grid
      container
      direction="column"
      style={{ textAlign: 'center', justifyContent: 'center' }}
    >
      <Grid item>
        <HeaderColor></HeaderColor>
      </Grid>
      {/** エラーメッセージを表示 */}
      {state.errorStatus !== null && (
        <Grid item>
          <Typography variant="h4" style={{ margin: 20 }}>
            {/** 指定箇所で改行させたいためArrayで表現する */}
            {getErrorMessage(state.errorStatus).map((message, key) => {
              return <div key={`error_message_${key}`}>{message}</div>;
            })}
          </Typography>
        </Grid>
      )}
      {/** 経路が見つからない場合のエラーのみ理由を表示 */}
      {state.errorStatus !== null &&
        state.errorStatus === ERROR_STATUS.NO_ROUTE && (
          <Grid item>
            <ResultStyle>
              <Typography variant="h5">
                次の理由が考えられます。
                <br />
                ・今いるバス停から徒歩圏内の目的地（スポット）を検索している
                <br />
                ・バスと鉄道を乗り継いでも目的地（スポット）へ到達できないスポットを検索している
                <br />
                ・バスや鉄道の本日の運行が終了している
                <br />
              </Typography>
            </ResultStyle>
          </Grid>
        )}

      {state.errorStatus !== null && (
        <Grid item>
          <Typography variant="h5" style={{ margin: 30 }}>
            別の目的地（スポット）を選択して検索しなおすか、
            <br />
            時間を置いてから再度お試しください。
          </Typography>
        </Grid>
      )}

      <Box>
        {/* 高蔵寺以外（名古屋市案件）の表示の場合。 */}
        {!props.isKozoji && (
          <>
            <Typography variant="h6" style={{ marginBottom: 10 }}>
              mixway webで検索する場合はこちら
            </Typography>
            <QRCodeSVG value={mixwayUrl} />
          </>
        )}
        {/* 高蔵寺案件用の表示の場合。 */}
        {props.isKozoji && <MoveKasugai />}
      </Box>
    </Grid>
  );
};

export default ErrorCourses;
