import React from 'react';
import { Grid } from '@mui/material';
import { sendLogs } from '../utils/sendLogs';
/**
 * 404ページ
 */
const NotFound: React.FC = () => {
  React.useEffect(() => {
    // ログを送る
    (async () => {
      await sendLogs(location.search, location.pathname);
    })();
  }, []);
  return (
    <Grid container style={{ textAlign: 'center' }}>
      <Grid item xs={12}>
        ページが見つかりませんでした。
      </Grid>
    </Grid>
  );
};

export default NotFound;
