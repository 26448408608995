import styled from '@emotion/styled';
import { useAppState, useSetAppState } from '../contexts/AppStateContext';
import { searchCourse as searchCourseNagoyaCity } from '../utils/api/search-course/nagoya-city';
import { searchCourse as searchCourseKozoji } from '../utils/api/search-course/kozoji';
import {
  filterCourses as filterCoursesNagoyaCity,
  getCourseColors as getCourseColorsNagoyaCity,
} from '../utils/nagoya-city';
import { DateTime } from 'luxon';
import { ERROR_STATUS, SearchError } from '../utils/error';
import UpdateIcon from '@mui/icons-material/Update';
import { debugLog } from '../utils/logeer';
import { isKozojiCode } from '../utils/params';
import { getCourseColors as getCourseColorsKozoji } from '../utils/kozoji';
import { getDepartureStationCode } from '../utils/course/point';

interface Props {
  arrivalPoineName: string;
  arrivalPointPosition: string;
  busStopCode: string;
  depDate: DateTime;
}

const ReSearch: React.FC<Props> = (props: Props) => {
  const state = useAppState();
  /** アプリケーション全体の状態を更新する関数。 */
  const setState = useSetAppState();
  /** 高蔵寺用の表示であるかを判定した結果。 */
  const isKozoji = isKozojiCode(props.busStopCode);
  /**
   * 名古屋市案件用の経路の再検索を実行する。
   * @returns 再検索後の名古屋市案件用の経路。
   */
  const researchNagoyaCity = async () => {
    /** ローディング状態にする */
    setState({
      ...state,
      isLoading: true,
    });
    /** 現在日時。 */
    const now = DateTime.now().set({
      second: 0,
      millisecond: 0,
    });
    // すでに格納されている経路の中で、現在日時に対して間に合う経路がないか確認する。
    const currentCourses = filterCoursesNagoyaCity(
      state.courses,
      props.busStopCode,
      now
    );
    debugLog(
      `現在保持している経路のうち、間に合う経路が${currentCourses.length}件あります`
    );
    // 間に合う経路が1件以上残っている場合。
    if (currentCourses.length > 0) {
      debugLog('間に合う経路があるため再検索はしません');
      const colors = getCourseColorsNagoyaCity(
        currentCourses[0],
        props.busStopCode
      );
      // 新経路情報に切り替える
      setState({
        ...state,
        isLoading: false,
        courses: currentCourses,
        color: colors,
      });
      return;
    }
    // 間に合う経路が残ってない場合には再検索をかける
    debugLog('間に合う経路がないため再検索をします');
    // 経路検索を実行する。
    const courses = await searchCourseNagoyaCity(
      now,
      props.arrivalPointPosition,
      props.busStopCode,
      props.arrivalPoineName
    );
    // 経路がない場合。
    if (courses.length === 0) {
      throw new SearchError(ERROR_STATUS.NO_ROUTE);
    }
    const colors = getCourseColorsNagoyaCity(courses[0], props.busStopCode);
    setState({
      ...state,
      isLoading: false,
      courses: courses,
      color: colors,
    });
  };

  /**
   * 高蔵寺案件用の経路の再検索を実行する。
   * @returns 再検索後の高蔵寺案件用の経路。
   */
  const researchKozoji = async () => {
    // ローディング状態にする。
    setState({
      ...state,
      isLoading: true,
    });
    /** 現在日時。 */
    const now = DateTime.now().set({
      second: 0,
      millisecond: 0,
    });
    // 経路検索を実行する。
    const courses = await searchCourseKozoji(
      props.arrivalPointPosition,
      props.arrivalPoineName,
      now
    );
    // 経路がない場合。
    if (courses.length === 0) {
      throw new SearchError(ERROR_STATUS.NO_ROUTE);
    }
    /** 再検索後に取得した経路の最初の地点（駅・バス停）の駅コード。 */
    const departureStationCode = getDepartureStationCode(
      courses[0].Route.Point
    );
    const colors = getCourseColorsKozoji(courses[0], departureStationCode);
    setState({
      ...state,
      isLoading: false,
      courses: courses,
      color: colors,
    });
  };

  const StyledButton = styled('button')({
    fontSize: '15px',
    marginTop: '10px',
    background: '#ffffff',
    borderRadius: '5px',
    border: '1px solid #ffffff',
    padding: '8px',
  });

  return (
    // 高蔵寺案件の場合は高蔵寺用の再検索処理とひも付ける。
    <StyledButton onClick={isKozoji ? researchKozoji : researchNagoyaCity}>
      <UpdateIcon sx={{ mb: -0.8, mr: 0.5 }} />
      <span
        style={{
          fontSize: '14px',
        }}
      >
        再検索する
      </span>
    </StyledButton>
  );
};

export default ReSearch;
