import { Grid, Typography } from '@mui/material';
import { LINE_TYPE } from '../../utils/api/mixway-utils';
import { getStopStationCount, getStopStationCountLabel } from './utils';

interface Props {
  /** 区間の種別。 */
  lineType: LINE_TYPE;
  /** 駅間の停車駅の数。 */
  stopStationCount: string;
}

/** 到着バス停（または駅）の案内部分のコンポーネント。 */
const ArrivalGuide: React.FC<Props> = (props: Props) => {
  /** 区間の停車駅（バス停）の数。 */
  const stopStationCount = getStopStationCount(props.stopStationCount);
  /** 区間の停車駅（バス停）の数の単位のラベル。 */
  const stopStationCountLabel = getStopStationCountLabel(props.lineType);
  return (
    <Grid container direction="column">
      <Grid item>
        <Typography component="span" sx={{ color: '#555555' }}>
          {stopStationCount}
          {stopStationCountLabel}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ArrivalGuide;
