import { LINE_TYPE } from '../../utils/api/mixway-utils';

/**
 * 到着バス停、または到着駅の案内コンポーネントを表示するか判定する関数。
 * @param lineType 区間の種別。
 * @returns 到着バス停、または到着駅の案内コンポーネントを表示するかのフラグ。
 */
export function isDisplayArrivalGuide(lineType: LINE_TYPE) {
  // 種別が徒歩ではない、かつ、その他ではない場合にtrueを返却する。
  return [LINE_TYPE.walk, LINE_TYPE.other].includes(lineType) === false;
}

/**
 * 半角数字のみの文字列であるかを判定する。
 * @param value 検証したい文字列。
 * @returns 半角数字のみの文字列であるか半定位した結果。
 */
export function isNumber(value: string) {
  const regexp = /^[0-9]+$/;
  return regexp.test(value);
}

/**
 * 区間の停車駅（バス停）の数を取得する。
 * @param value 駅すぱあと経路の区間の停車駅（バス停）の数。
 * @returns 区間の停車駅（バス停）の数。
 */
export function getStopStationCount(value: string) {
  return isNumber(value) ? Number(value) + 1 : 0;
}

/**
 * 区間の停車駅（バス停）の数の単位を取得する。
 * @param lineType 区間の種別。
 * @returns 区間の停車駅（バス停）の数の単位（「駅」。）
 */
export function getStopStationCountLabel(lineType: LINE_TYPE) {
  switch (lineType) {
    case LINE_TYPE.train:
      return '駅';
    case LINE_TYPE.bus:
      return '駅';
    default:
      return '';
  }
}

/**
 * 駅（バス停）の末尾に付与するラベルを取得する。
 * @param type 駅の種別。
 * @returns 停車駅（バス停）の末尾に付与するラベル。
 */
export function getStationSuffix(type: string) {
  switch (type) {
    case LINE_TYPE.train:
      return '駅';
    case LINE_TYPE.bus:
      return 'バス停';
    default:
      return '';
  }
}
