import { DateTime } from 'luxon';
import { Coordinate } from './coordinate';
import { ERROR_STATUS } from './error';
import { is4digits, is8digits, isCoordinate } from './string';

export const getDateTimeFromString = (
  departureDate: string,
  departureTime: string
): luxon.DateTime => {
  /** 現在の日時。 */
  const currentDate = DateTime.local({ zone: 'Asia/Tokyo' });
  const dateTime = currentDate;
  // 年月日・時刻の初期値に現在日時を設定する。
  let year = currentDate.year;
  let month: number = currentDate.month;
  let day: number = currentDate.day;
  let hour: number = currentDate.hour;
  let minute: number = currentDate.minute;

  // 日付部分に期待通りの値が設定されている場合。
  if (is8digits(departureDate)) {
    const date = DateTime.fromFormat(departureDate, 'yyyyMMdd');
    year = date.year;
    month = date.month;
    day = date.day;
  }
  // 時刻部分に期待通りの値が設定されている場合。
  if (is4digits(departureTime)) {
    const time = DateTime.fromFormat(departureTime, 'HHmm');
    hour = time.hour;
    minute = time.minute;
  }
  // 秒・ミリ秒を調整して返す。
  return dateTime.set({
    year: year,
    month: month,
    day: day,
    hour: hour,
    minute: minute,
    second: 0,
    millisecond: 0,
  });
};

/**
 * 出発日時を取得する。
 * @param searchParams URLパラメーター（location.search）。
 * @returns 出発日時。
 */
export const getDepDate = (params: URLSearchParams) => {
  /** パラメーターで指定された出発日付。 */
  const departureDate = getParams(params, 'departureDate');
  /** パラメーターで指定された出発時刻。 */
  const departureTime = getParams(params, 'departureTime');
  /** 出発日時。 */
  const depDate = getDateTimeFromString(departureDate, departureTime);
  return depDate;
};

/**
 * 到着地の情報を取得する。
 * @param searchParams URLパラメーター（location.search）。
 * @returns 到着地の情報。
 */
export const getArrPoint = (params: URLSearchParams) => {
  /** 目的地の名称。 */
  const arrivalPointName = getParams(params, 'arrivalPointName');
  /** 目的地の緯度経度（座標）。 */
  const arrivalPosition = getParams(params, 'arrivalPosition');
  return {
    /** 緯度経度。 */
    location: arrivalPosition,
    /** 名称。 */
    name: arrivalPointName,
  };
};

/**
 * パラメーターに関するエラーメッセージを取得する。
 * @param searchParams URLパラメーター。
 * @returns パラメーターに関するエラーメッセージの配列。
 */
export const getErrorMessages = (params: URLSearchParams) => {
  const errorMessages = [];
  /** 目的地。 */
  const arrPoint = getArrPoint(params);
  /** バス停コード。 */
  const busStopCode = getBusStopCode(params);
  // バス停コードが未設定の場合。
  if (busStopCode === '') {
    errorMessages.push(ERROR_STATUS.NO_SET_BUS_STOP_CODE);
  }
  //指定された座標が緯度経度の形式になっていない場合。
  if (!isCoordinate(arrPoint.location)) {
    errorMessages.push(ERROR_STATUS.INVALID_ARRIVAL_POINT);
  } else {
    const coordinate = new Coordinate(arrPoint.location);
    if (coordinate.isInvalid()) {
      console.error(
        `対象の文字列は座標のフォーマットを満たしていません: ${getParams(
          params,
          'arrivalPosition'
        )}`
      );
      errorMessages.push(ERROR_STATUS.INVALID_ARRIVAL_POINT);
    }
  }
  return errorMessages;
};

/**
 * URLパラメーターの値を取得する。
 * @param params URLパラメーター。
 * @param key 参照したいパラメーターのキー。
 * @returns 指定したキーの値。（未設定の場合は空文字が返却される。）
 */
export const getParams = (params: URLSearchParams, key: string) => {
  // パラメーターに参照したいキーが存在しない場合、空文字を返却する。
  if (!params.has(key)) {
    return '';
  }
  /** パラメーターから取得した値。 */
  const value = params.get(key);
  // 値がnullの場合は空文字、それ以外の場合はそのまま返却する。
  return value === null ? '' : value;
};

/**
 * パラメーターで指定されたバス停コードの値を取得する。
 * @param params URLパラメーター。
 * @returns パラメーターで指定されたバス停コードの値。
 */
export const getBusStopCode = (params: URLSearchParams) => {
  return getParams(params, 'busStopCode');
};

/**
 * デバッグモードであるか判定する。
 * @param value debugパラメーターに設定された値。
 * @returns デバッグモードであるか判定した結果。
 */
export const isDebug = (value: string) => {
  return value === 'showDetailCourses';
};

/**
 * 高蔵寺用の表示に切り替える値であるかを判定。
 * @param value busStopCodeパラメーターで指定された値。
 * @returns 高蔵寺用の表示に切り替える値であるかを判定した結果。
 */
export const isKozojiCode = (value: string) => {
  return value === 'kozoji';
};
