import axios from 'axios';
import { ERROR_STATUS } from './error';

const SEND_LOG_HOST =
  'https://0jt1hcuj3m.execute-api.ap-northeast-1.amazonaws.com';
const SEND_LOG_PATH = '/prod/logs';
const SEND_ERROR_LOG_PATH = '/prod/errorLog';

/**
 * ログをCloudWatchに送信する。
 * @param searchParams URLパラメーターの文字列。
 * @param pathName パス名。
 */
export const sendLogs = async (searchParams: string, pathName: string) => {
  try {
    const url = `${SEND_LOG_HOST}${SEND_LOG_PATH}`;
    const urlParams = new URLSearchParams(searchParams);
    // 現在のPathを追加する
    urlParams.append('path', pathName);
    // 現在の環境を追加する
    urlParams.append('env', process.env['REACT_APP_ENV'] || '');
    const params: { [key: string]: string } = {};
    urlParams.forEach((value, key) => {
      params[key] = value;
    });
    const response = await axios.get(url, {
      params: params,
    });
    return response;
  } catch (e) {
    console.error(e);
    sendErrorLog(e, ERROR_STATUS.UNEXPECTED_ERROR);
  }
};

/**
 * エラーのログを送信する
 * @param e エラーオブジェクト
 * @param errorType エラーのタイプ
 * @returns
 */
export const sendErrorLog = async (e: any, errorType: string) => {
  try {
    console.log('error');
    const url = `${SEND_LOG_HOST}${SEND_ERROR_LOG_PATH}`;
    const response = await axios.post(url, {
      errorBody: e.message,
      errorStack: e.stack,
      errorType: errorType,
      env: process.env['REACT_APP_ENV'] || '',
    });
    console.log(response);
    return response;
  } catch (e) {
    console.error(e);
  }
};
