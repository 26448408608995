import { useAppState } from '../../contexts/AppStateContext';
import { Alert, Grid, styled } from '@mui/material';
import {
  getDepartureNo,
  getDestination,
  getLineType,
} from '../../utils/api/mixway-utils';
import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import ReSearch from '../ReSearch';
import {
  getSouthBusPoleNumber,
  isMatchCourse,
} from '../../utils/api/search-course/kozoji';
import {
  getDepartureStationCode,
  getDestinationName,
} from '../../utils/course/point';
import {
  getBusLineName,
  getCourseColors,
  isNorthBusPole,
} from '../../utils/kozoji';
import DepartureBusStop from './DepartureBusStop';
import Destination from './Destination';
import { NextBusDialog } from './NextBusDialog';

interface Props {
  /** 出発日時。 */
  depDate: DateTime;
  /** 目的地の緯度経度。 */
  arrivalPointPosition: string;
  /** URLパラメーターで指定されたバス停コード。 */
  busStopCode: string;
}

/** ヘッダー（帯）のコンポーネント。 */
const Header: React.FC<Props> = (props: Props) => {
  const state = useAppState();
  /** 現在表示している経路。 */
  const course = state.courses[state.courseIndex];
  /** 最初の区間。 */
  const firstLine = course.Route.Line[0];
  /** 最初の区間種別。 */
  const firstLineType = getLineType(firstLine.Type);
  /** 最初の区間でバスを利用するかを判定した結果。 */
  const useBus = firstLineType === 'bus';

  /** バス出発時刻 */
  const [depBusDate, setBusDepDate] = useState<DateTime>();
  /** バス行き先 */
  const [destination, setDestination] = useState<string>('');
  /** バス乗り場情報 */
  const [busRideSpot, setBusRideSpot] = useState<string>('');
  /** バス会社名 */
  const [companyName, setCompanyName] = useState<string>('');
  /** バスが出発済みか */
  const [isBusAlreadyLeft, switchIsBusAlreadyLeft] = useState<boolean>(false);

  /** 目的地 */
  const [arrivalPointName, setArrivalPointName] = useState<string>('');
  /** ヘッダー（帯）のカラー */
  const [headerColor, setHeaderColor] = useState<string>('');
  /** フォントカラー */
  const [fontColor, setFontColor] = useState<string>('');
  /** 高蔵寺駅出口 */
  const [exitDirection, setExitDirection] = useState<string>('');
  /** 高蔵寺駅の南口かどうか */
  const [isSouthExit, setIsSouthExit] = useState<boolean>(false);

  /** ヘッダー固定部分のスタイル */
  const HeaderStickyGrid = styled(Grid)({
    background: headerColor,
    color: fontColor,
    position: 'sticky',
    top: 0,
    zIndex: 1,
    textAlign: 'center',
  });

  /** ヘッダーコンテンツ部分 */
  const CenterContentGrid = styled(Grid)({
    height: '150px',
    maxWidth: '863px',
    margin: 'auto',
  });

  /** 次の便で検索するのエリア */
  const NextBusSearchArea = styled(Grid)({
    height: '60px',
    margin: 'auto',
    background: '#888888',
  });

  /** 1秒毎に表示中の経路が出発済みかを判定する */
  useEffect(() => {
    const timerID = setInterval(() => {
      if (state.courses.length === 0) {
        return;
      }
      const course = state.courses[state.courseIndex];
      /** 参照している経路の最初の区間。 */
      const firstLine = course.Route.Line[0];
      const firstPoint = course.Route.Point[0];
      const now = DateTime.local({ zone: 'Asia/Tokyo' }).set({
        second: 0,
        millisecond: 0,
      });
      /** バス出発時刻を過ぎた場合タイマーを停止する */
      const stopTime = !isMatchCourse(firstLine, firstPoint, now);
      switchIsBusAlreadyLeft(stopTime);
      if (stopTime) {
        clearInterval(timerID);
      }
    }, 1000);
  }, [state.courses[state.courseIndex || 0]]);

  useEffect(() => {
    try {
      const course = state.courses[state.courseIndex];
      if (!course) {
        return;
      }
      /** 参照している経路の地点（駅、バス停など）の配列。 */
      const points = course.Route.Point;
      /** 目的地の名称。 */
      setArrivalPointName(getDestinationName(points));
      /** 出発バス停コード */
      const depStationCode = getDepartureStationCode(points);
      /** カラー */
      const colors = getCourseColors(course, depStationCode);
      setHeaderColor(colors.header);
      setFontColor(colors.font);

      const firstLine = course.Route?.Line[0];
      /** 出発時刻 */
      const depDate = DateTime.fromISO(
        firstLine?.DepartureState?.Datetime?.text
      );
      setBusDepDate(depDate);

      /** 行き先を取得する */
      setDestination(getDestination(firstLine.Destination));
      /** バス路線名を取得する */
      setCompanyName(getBusLineName(firstLine) || '');
      /** 高蔵寺駅北口であるかを表すフラグ。 */
      const isNorth = isNorthBusPole(depStationCode);
      // 高蔵寺駅北口の場合。
      if (isNorth) {
        setExitDirection('北口');
        /** バスの乗り場番号。 */
        const busPoleNumber = getDepartureNo(firstLine);
        setBusRideSpot(busPoleNumber);
      } else {
        // 高蔵寺駅南口の場合。
        setExitDirection('南口');
        setIsSouthExit(true);
        /** バス乗り場番号。 */
        const busPoleNumber = getSouthBusPoleNumber(firstLine.Name);
        setBusRideSpot(busPoleNumber);
      }
    } catch (e) {
      console.error(e);
    }
  }, [JSON.stringify(state)]);

  const HeaderGrid = styled(Grid)({
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  });

  /** 「発車予定」の文字列を表示するスタイル */
  const DepScheduleSpan = styled('span')({
    fontSize: '18px',
    position: 'relative',
    left: '20px',
    top: '42px',
  });

  /** 出発時刻を表示するスタイル */
  const DepTimeSpan = styled('span')({
    fontSize: '64px',
  });

  return (
    <Grid container direction="column">
      <HeaderStickyGrid item>
        {state.isDummyCourse && (
          <Alert severity="error">これはダミー経路です。</Alert>
        )}
        <CenterContentGrid container>
          {/** バスのりば部分。 */}
          <Grid item xs="auto" sx={{ textAlign: 'center' }}>
            <DepartureBusStop
              exitDirection={exitDirection}
              busRideSpot={busRideSpot}
            ></DepartureBusStop>
          </Grid>
          <Grid item xs={8} sx={{ marginLeft: '20px', marginTop: '10px' }}>
            <Grid container direction="column">
              <Grid item xs="auto">
                {/** バスが出発済みでないなら次のバスの出発時刻を表示する */}
                {!isBusAlreadyLeft && (
                  <Grid container direction="row" alignItems="stretch">
                    <Grid item>
                      <DepTimeSpan>{depBusDate?.toFormat('HH:mm')}</DepTimeSpan>
                    </Grid>
                    <Grid item>
                      <DepScheduleSpan>発車予定</DepScheduleSpan>
                    </Grid>
                  </Grid>
                )}
                {/** バスが出発済みなら再検索ボタンを表示する */}
                {isBusAlreadyLeft && (
                  <Grid container direction="row">
                    <HeaderGrid item>
                      <span
                        style={{
                          fontSize: '40px',
                          marginRight: '20px',
                        }}
                      >
                        発車済み
                      </span>
                    </HeaderGrid>
                    <HeaderGrid item>
                      <ReSearch
                        arrivalPoineName={arrivalPointName}
                        arrivalPointPosition={props.arrivalPointPosition}
                        busStopCode={props.busStopCode}
                        depDate={props.depDate}
                      />
                    </HeaderGrid>
                  </Grid>
                )}
              </Grid>
              {/** 路線名・方面 */}
              <Grid item xs="auto">
                <Destination
                  isSouthExit={isSouthExit}
                  destination={destination}
                  busLineName={companyName}
                ></Destination>
              </Grid>
            </Grid>
          </Grid>
        </CenterContentGrid>
        {/** 発車済みではない、かつ、最初の区間でバスを利用する経路の場合、「つぎの便」ボタンを表示する。 */}
        {useBus && (
          <NextBusSearchArea container alignItems="center">
            <Grid item xs={8}></Grid>
            <Grid item xs={4}>
              <NextBusDialog />
            </Grid>
          </NextBusSearchArea>
        )}
      </HeaderStickyGrid>
    </Grid>
  );
};

export default Header;
