import { Coordinate } from '../../coordinate';
import TARGET_AREAS from './qrcode_areas.json';

/** 対象半径とする地点の許容半径 */
const TARGET_RADIUS_DISTANCE = 50;

export interface TargetArea {
  name: string;
  location: {
    lat: number;
    lng: number;
  };
  url: string;
  distance: number;
}

/**
 * 二点間の距離を求める(ヒュベニの公式)
 * @param dep 出発地地点情報
 * @param arr 到着地地点情報
 * @returns 2点感の距離(m)
 */
const calcDistance = (dep: Coordinate, arr: Coordinate): number => {
  const rad = (deg: number) => {
    return (deg * Math.PI) / 180;
  };
  const dep_red_point = {
    lat: rad(dep.lat),
    lng: rad(dep.lng),
  };
  const arr_red_point = {
    lat: rad(arr.lat),
    lng: rad(arr.lng),
  };

  // 緯度差
  const lat_diff = dep_red_point.lat - arr_red_point.lat;
  // 経度差算
  const lng_diff = dep_red_point.lng - arr_red_point.lng;
  // 平均緯度
  const lat_avg = (dep_red_point.lat + arr_red_point.lat) / 2.0;
  // 赤道半径
  const equator_rad = 6378137.0;
  // 第一離心率^2
  const e2 = 0.00669438002301188;
  // 赤道上の子午線曲率半径
  const a1e2 = 6335439.32708317;
  const sinLat = Math.sin(lat_avg);
  const W2 = 1.0 - e2 * (sinLat * sinLat);
  // 子午線曲率半径M
  const M = a1e2 / (Math.sqrt(W2) * W2);
  // 卯酉線曲率半径
  const N = equator_rad / Math.sqrt(W2);
  const t1 = M * lat_diff;
  const t2 = N * Math.cos(lat_avg) * lng_diff;
  return Math.floor(Math.sqrt(t1 * t1 + t2 * t2));
};

/**
 * 目的地がmoveかすがいの表示対象エリアであるか判定し、表示対象であればURLを取得する。
 * @param arrivalPoint 目的地の緯度経度。
 * @returns QRコードに設定するURL。
 */
export const getMoveKasugaiUrl = (arrivalPoint: Coordinate) => {
  const marchAreaList = TARGET_AREAS.map((area) => {
    const areaCoordinate = new Coordinate(
      `${area.location.lat},${area.location.lng}`
    );
    return {
      ...area,
      distance: calcDistance(arrivalPoint, areaCoordinate),
    };
  })
    // 指定された地点から半径50m以内のエリアに絞り込む。
    .filter((area) => {
      return area.distance < TARGET_RADIUS_DISTANCE;
    })
    // 距離の近いエリア順に並び替える。
    .sort((a, b) => {
      return a.distance - b.distance;
    });
  // 目的地からもっとも距離の近いエリアを返却する。
  return marchAreaList.length > 0 ? marchAreaList[0].url : null;
};
