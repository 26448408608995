import React from 'react';
import { Typography, styled } from '@mui/material';
import qr from './move_kasugai_QR.png';

/** 「move!かすがい」のQRコード部分のコンポーネント。 */
const MoveKasugai: React.FC = () => {
  const ResultImg = styled('img')({
    width: '146px',
  });

  return (
    <>
      <Typography variant="h6" style={{ marginBottom: 10 }}>
        move！かすがいルート検索はこちら
      </Typography>
      <ResultImg src={qr} />
    </>
  );
};

export default MoveKasugai;
