/**
 * 4ケタの数字であるかを判定する。
 * @param value 判定したい文字列。
 * @returns 4ケタの数字であるかを判定した結果。
 */
export const is4digits = (value: string) => {
  return /^\d{4}$/.test(value);
};

/**
 * 8ケタの数字であるかを判定する。
 * @param value 判定したい文字列。
 * @returns 8ケタの数字であるかを判定した結果。
 */
export const is8digits = (value: string) => {
  return /^\d{8}$/.test(value);
};

/**
 * 緯度経度の文字列であるかを判定する。
 * @param value 判定したい文字列。
 * @returns 緯度経度の文字列であるかを判定した結果。
 */
export const isCoordinate = (value: string) => {
  return /^\d{1,2}\.\d{1,},\d{1,3}\.\d{1,}$/.test(value);
};
