/** バス時刻表パスのパラメータ。 */
// https://redmine.val.jp/projects/webservice/wiki/路線時刻表#-bustimetable
export class BusTimetableParams {
  from: string;
  to: string;
  date: string;
  addPole: boolean;
  /**
   * コンストラクタ。
   * @param from 出発バス停の駅コード。
   * @param to 到着バス停の駅コード。
   * @param date 日付。（yyyyMMdd形式）
   */
  constructor(from: string, to: string, date: string) {
    this.from = from;
    this.to = to;
    this.date = date;
    this.addPole = true;
  }
}
