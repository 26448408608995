import { useEffect, useState } from 'react';
import {
  AppState,
  useAppState,
  useSetAppState,
} from '../../contexts/AppStateContext';

import LoadingCircle from './../LoadingCircle';
import ErrorCourses from '../Course/ErrorCourses';
import { searchCourse } from '../../utils/api/search-course/kozoji';
import {
  getArrPoint,
  getBusStopCode,
  getDepDate,
  getErrorMessages,
  getParams,
} from '../../utils/params';
import { ERROR_STATUS, SearchError } from '../../utils/error';
import { isValidKey } from '../../utils/environment';
import NotFound from '../NotFound';
import { Grid, Typography } from '@mui/material';
import Result from './Result';
import { sendErrorLog, sendLogs } from '../../utils/sendLogs';

/** ダミー経路を表示するデモモードか */
const isDemoMode = process.env['REACT_APP_DEMO_MODE'] === 'true' || false;

/** 高蔵寺駅で検索した場合の経路を表示するコンポーネント。 */
const Kozoji: React.FC = () => {
  const state = useAppState();
  /** アプリケーション全体の状態を更新する関数。 */
  const setState = useSetAppState();

  /** URLパラメーター。 */
  const params = new URLSearchParams(location.search);
  /** 出発日時。 */
  const depDate = getDepDate(params);
  /** 到着地。 */
  const arrPoint = getArrPoint(params);
  /** バス停コード。 */
  const busStopCode = getBusStopCode(params);
  /** debugパラメーター。 */
  const debug = getParams(params, 'debug');
  /** keyパラメーター。 */
  const key = getParams(params, 'key');

  /** アクセスを許可するかを表すフラグ。 */
  const [isAvalable, switchIsAvalable] = useState(true);

  const isError = (state: AppState) => {
    return state.isError || state.courses.length === 0;
  };
  const hasCourse = () => {
    return (
      !state.isLoading &&
      isAvalable &&
      !isError(state) &&
      state.courses.length > 0
    );
  };
  const isShowErrorCourse = () => {
    return !state.isLoading && isAvalable && isError(state);
  };
  let initCall = true;

  /** 初期読み込み時にクエリより経路検索を実行する */
  useEffect(() => {
    (async () => {
      /**
       * useEffectで2回呼ばれてしまうため、対処療法的に初回のみ検索をかける
       * 初回読み込み判定変数をuseStateで取り扱うとタイミング的にうまく発火しないので通常変数を利用する
       */
      if (!initCall) {
        sendLogs(location.search, location.pathname);
        return <Typography>経路が取得できませんでした。</Typography>;
      }
      initCall = false;
      try {
        /** パラメーターに関するエラーメッセージの配列。 */
        const paramsErrorMessages = getErrorMessages(params);
        /** パラメーターに関してエラーがあるかを判定した結果。 */
        // エラーメッセージが1件以上存在する場合をエラーがあるとみなす。
        const hasInvalidParams = paramsErrorMessages.length > 0;
        /** 有効なキー情報が設定されているかを表すフラグ。 */
        const isValid = isValidKey(key);
        switchIsAvalable(isValid);

        // 下記3つの条件をいずれも満たす場合、例外を発生させる。
        // 1.パラメーターに不備がある。
        // 2.デモモードではない。
        // 3.debugパラメーターが未設定である。
        if (hasInvalidParams && !isDemoMode && debug === '') {
          throw new SearchError(paramsErrorMessages[0]);
        }

        // 無効なキーが設定されていた場合は、経路検索をせず終了する。
        if (!isValid) {
          setState({
            ...state,
            isLoading: false,
          });
          return;
        }

        // 読み込み中フラグをオンにする。
        setState({
          ...state,
          isLoading: true,
        });

        /** 経路。 */
        const courses = await searchCourse(
          arrPoint.location,
          arrPoint.name,
          depDate
        );
        /** 経路がない場合、NO_ROUTEを設定 */
        if (courses.length === 0) {
          throw new SearchError(ERROR_STATUS.NO_ROUTE);
        }
        // 読み込み中フラグをオフにし、取得した経路をセットする。
        setState({
          ...state,
          isLoading: false,
          courses: courses,
        });
      } catch (e: unknown) {
        const errorStatus: ERROR_STATUS = await (async () => {
          if (!(e instanceof SearchError)) {
            await sendErrorLog(e, ERROR_STATUS.UNEXPECTED_ERROR);
            return ERROR_STATUS.UNEXPECTED_ERROR;
          }
          switch (e.message) {
            case ERROR_STATUS.NO_ROUTE:
            case ERROR_STATUS.INVALID_QUERY:
            case ERROR_STATUS.INVALID_ARRIVAL_POINT:
            case ERROR_STATUS.NO_SET_BUS_STOP_CODE:
            case ERROR_STATUS.MIXWAY_API_ERROR:
              await sendErrorLog(e, e.message);
              return e.message;
            default:
              await sendErrorLog(e, ERROR_STATUS.UNEXPECTED_ERROR);
              return ERROR_STATUS.UNEXPECTED_ERROR;
          }
        })();
        // エラーが起きた場合には isError をtrueにする
        setState({
          ...state,
          isLoading: false,
          isError: true,
          errorStatus: errorStatus,
        });
      }
    })();
  }, []);

  return (
    <>
      {/** ロード中表示 */}
      {state.isLoading && (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={1}>
            <LoadingCircle />
          </Grid>
        </Grid>
      )}
      {/* アクセスが許可されていない（無効なキーが設定されている）場合。 */}
      {!isAvalable && <NotFound />}
      {/** エラーの場合 */}
      {isShowErrorCourse() && <ErrorCourses isKozoji />}
      {/** 経路を表示可能な場合、結果部分を表示する。 */}
      {hasCourse() && (
        <Result
          arrivalPosition={arrPoint.location}
          depDate={depDate}
          busStopCode={busStopCode}
        />
      )}
    </>
  );
};

export default Kozoji;
