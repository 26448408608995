import * as React from 'react';
import { Typography, Grid } from '@mui/material';

/** つぎの便を取得できなかった場合に表示するコンポーネント。  */
export const NotExists: React.FC = () => {
  return (
    <Grid container justifyContent="center" sx={{ marginTop: 5 }}>
      <Grid item>
        <Typography variant="h5">つぎの便を取得できませんでした。</Typography>
      </Grid>
    </Grid>
  );
};
