import React from 'react';
import { Container, styled } from '@mui/material';
import kozojiMapImage from '../../../images/map_kozoji_station.png';
import kozojiNorth1MapImage from '../../../images/map_kozoji_north_1.png';
import kozojiNorth2MapImage from '../../../images/map_kozoji_north_2.png';
import kozojiNorth3MapImage from '../../../images/map_kozoji_north_3.png';
import kozojiNorth4MapImage from '../../../images/map_kozoji_north_4.png';
import kozojiNorth5MapImage from '../../../images/map_kozoji_north_5.png';
import kozojiNorth8MapImage from '../../../images/map_kozoji_north_8.png';
import kozojiSouth1MapImage from '../../../images/map_kozoji_south_1.png';
import kozojiSouth2MapImage from '../../../images/map_kozoji_south_2.png';
import kozojiSouth3MapImage from '../../../images/map_kozoji_south_3.png';
import { useAppState } from '../../../contexts/AppStateContext';
import { getDepartureStationCode } from '../../../utils/course/point';
import { isNorthBusPole } from '../../../utils/kozoji';
import { getDepartureNo } from '../../../utils/api/mixway-utils';
import { getSouthBusPoleNumber } from '../../../utils/api/search-course/kozoji';

/**
 * バス停位置画像（マップ）のコンポーネント。
 */
const Map: React.FC = () => {
  const state = useAppState();
  const course =
    state.courses.length > 0 ? state.courses[state.courseIndex] : undefined;
  // 表示する経路が空の場合、何も表示しない。
  if (!course) {
    return null;
  }
  /** 画像用のスタイル */
  const MapImageStyle = styled('img')({
    width: '90%',
  });

  /** 出発バス停コード */
  const depStationCode = getDepartureStationCode(course.Route.Point);
  /** 北口かどうかを表すフラグ。 */
  const isNorthExit = isNorthBusPole(depStationCode);
  /** 経路の最初の区間。 */
  const firstLine = course.Route.Line[0];
  /** バスの乗り場番号。 */
  const busPoleNo = isNorthExit
    ? getDepartureNo(firstLine)
    : getSouthBusPoleNumber(firstLine.Name);

  /** 現状乗り場毎の画像はなく、固定の画像を表示する */
  const mapImageSrc = (() => {
    if (isNorthExit) {
      /** 高蔵寺駅北口 */
      switch (busPoleNo) {
        case '1':
          return kozojiNorth1MapImage;
        case '2':
          return kozojiNorth2MapImage;
        case '3':
          return kozojiNorth3MapImage;
        case '4':
          return kozojiNorth4MapImage;
        case '5':
          return kozojiNorth5MapImage;
        case '8':
          return kozojiNorth8MapImage;
        default:
          return kozojiMapImage;
      }
    } else {
      /** 高蔵寺駅南口 */
      switch (busPoleNo) {
        case '1':
          return kozojiSouth1MapImage;
        case '2':
          return kozojiSouth2MapImage;
        case '3':
          return kozojiSouth3MapImage;
        default:
          return kozojiMapImage;
      }
    }
  })();

  return (
    <div style={{ margin: 20, textAlign: 'center' }}>
      <Container>
        <MapImageStyle src={mapImageSrc} alt="バス停マップ" />
      </Container>
    </div>
  );
};

export default Map;
