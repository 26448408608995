export function getWalkLabel(timeOnBoard: number, isLastSection: boolean) {
  // 最後の区間の場合、距離も含めたラベルを返却する。
  if (isLastSection) {
    const walkDistance = getWalkDistance(timeOnBoard);
    return `徒歩 ${timeOnBoard}分（${walkDistance}m)`;
  } else {
    // 最後以外の区間の場合は距離を含めないラベルを返却する。
    return `徒歩 ${timeOnBoard}分`;
  }
}

/**
 * 所要時間から徒歩距離を取得する。
 * @param timeOnBoard 所要時間。
 * @returns 徒歩の距離。
 */
export function getWalkDistance(timeOnBoard: number) {
  /** １分間に歩く距離 */
  const WALK_PER_MINUTE = Number(
    process.env['REACT_APP_WALK_PER_MINUTE'] || '80'
  );
  return timeOnBoard * WALK_PER_MINUTE;
}

export function getDestinationLabel(destination: string) {
  return `${destination}行`;
}

export function getCorporationName(value: string) {
  return value.split('・')[0];
}
