import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import { LINE_TYPE } from '../../../utils/api/mixway-utils';
import TrainIcon from './TrainIcon';

interface Props {
  type: LINE_TYPE;
}

/** 交通種別ごとのアイコン。 */
const TrafficType: React.FC<Props> = (props: Props) => {
  switch (props.type) {
    case LINE_TYPE.walk:
      return <DirectionsWalkIcon sx={{ fontSize: 32, color: '#555555' }} />;
    case LINE_TYPE.bus:
      return <DirectionsBusIcon sx={{ fontSize: 32, color: '#555555' }} />;
    case LINE_TYPE.train:
      return <TrainIcon />;
    default:
      return null;
  }
};

export default TrafficType;
