import React from 'react';
import { Container, styled } from '@mui/material';
import hirokojihonmachiMapImage from '../images/hirokojihonmachi_bus.png';
import nayabashiMapImage from '../images/nayabashi_bus.png';
import { BUS_STOP_CODES } from '../utils/api/search-course/nagoya-city/bus-stop-codes';

/**
 * バス停位置画像（マップ）のコンポーネント。
 */
interface Props {
  busStopCode: string;
}

const MapArea: React.FC<Props> = (props: Props) => {
  /** 画像用のスタイル */
  const MapImageStyle = styled('img')({
    maxHeight: '500px',
    maxWidth: '500px',
  });

  /** 広小路本町か納屋橋かを判定 */
  const mapImageSrc = (() => {
    if (props.busStopCode === BUS_STOP_CODES.nayabashi_2) {
      return nayabashiMapImage;
    } else {
      return hirokojihonmachiMapImage;
    }
  })();

  return (
    <div style={{ marginTop: 30, textAlign: 'center' }}>
      <Container maxWidth="sm">
        <MapImageStyle src={mapImageSrc} alt="バス停マップ" />
      </Container>
    </div>
  );
};

export default MapArea;
