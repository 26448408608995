import { Course } from '../../types/mixway-api/extreme';
import { toJSTDatetime } from '../datetime';

/**
 * 経路をコンポーネントで描画しやすいよう整形する。
 * @param course mixway APIから取得した経路（1経路）。
 * @param arrivalPointName 目的地の名称。
 * @returns 整形後の経路。
 */
export function modifyCourse(course: Course, arrivalPointName: string) {
  const route = course.Route;
  let points = route.Point;
  let lines = Array.isArray(route.Line) ? route.Line : [route.Line];
  const lastLineIndex = lines.length - 1;
  /** 目的地。 */
  const destination = points[lastLineIndex + 1];
  // 最初の区間が徒歩の場合、経路の最初の区間・地点を取り除く。
  if (lines[0].Type === 'walk' || lines[0].Name === '徒歩') {
    points = points.slice(1);
    lines = lines.slice(1);
  }
  // 目的地（最後の地点）の名称をURLパラメーターで与えられた名称に変更する。
  if (Object.keys(destination).includes('Name')) {
    destination.Name = arrivalPointName;
  }
  // 整形した地点・区間の要素を元の経路のデータに格納する。
  course.Route.Point = points;
  course.Route.Line = lines;
  return course;
}

/**
 * 経路全体の出発日時（経路の最初の区間出発日時）を取得する。
 * @param course 経路。
 * @returns 経路全体の出発日時（経路の最初の区間出発日時）。
 */
export function getDepartureDateText(course: Course) {
  return course.Route.Line[0].DepartureState.Datetime.text;
}

/**
 * 複数の経路を出発日時の早い順に並び替える。
 * @param courses 経路全件。
 * @returns 出発日時の早い順に並び替えられた経路の配列。
 */
export function sortByDepartureTime(courses: Course[]) {
  return courses.sort((a, b) => {
    const dep1 = getDepartureDateText(a);
    const dep2 = getDepartureDateText(b);
    // 最初の区間の出発日時の早い順に並び替える。
    return toJSTDatetime(dep1).toMillis() - toJSTDatetime(dep2).toMillis();
  });
}
