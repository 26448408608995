import axios from 'axios';
import { ERROR_STATUS } from '../error';
import { BusTimetableParams } from './bus-timetable/params';

enum Path {
  BusTimeTable = '/v1/json/bus/timetable',
}

const instance = axios.create({
  baseURL: process.env['REACT_APP_EWS_ENDPOINT'],
  timeout: 7000,
});

// リクエスト時の共通処理。
instance.interceptors.request.use((request) => {
  request.params = {
    ...request.params,
    // keyパラメーター（EWS/mixway APIのキー）を設定する。
    key: process.env['REACT_APP_MIXWAY_API_KEY'] || '',
  };
  return request;
});
// レスポンスを受け取ったときの共通処理。
instance.interceptors.response.use(
  (response) => response,
  // ステータスコード200以外が返却された場合。
  (error) => {
    // ステータスコードに応じて処理を切り替えられるようにしているが、
    // 現状は常にEWSのエラーである例外を投げるようにしている。
    switch (error.response?.status) {
      default:
        throw Error(ERROR_STATUS.MIXWAY_API_ERROR);
    }
  }
);

/**
 * バス時刻表を取得する。
 * @param params バス時刻表APIのパラメーター。
 * @returns バス時刻表。
 */
export const getBusTimetable = async (params: BusTimetableParams) => {
  const response = await instance.get(Path.BusTimeTable, { params: params });
  // 便情報（「ResultSet/TimeTable/Line」要素）が存在する場合のみ返却する。
  if (
    response.data.ResultSet.TimeTable &&
    response.data.ResultSet.TimeTable.Line
  ) {
    return response.data.ResultSet.TimeTable.Line;
  } else {
    // 便情報が取得できなかった場合は空配列を返却する。
    return [];
  }
};
