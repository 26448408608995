import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useAppState } from '../../contexts/AppStateContext';
import nearBusStopInfo from '../../data/nearBusStopInfo.json';
import { getBusSystemNumber } from '../../utils/api/mixway-utils';
import Arrow from './Arrow';
import { isYellow } from './Arrow/util';

export interface NearBusStopInfoType {
  [key: string]: {
    [key: string]: {
      /** 矢印表示に使う向き */
      direction: string;
      /** 方向情報 */
      label: string;
      /** バス停までの徒歩時間 */
      walkTime: number;
    };
  };
}

const NEAR_BUSSTOP_INFOMATION: NearBusStopInfoType = nearBusStopInfo;

interface Props {
  busRideSpot: string;
  /** バス停コード。 */
  busStopCode: string;
}

/**
 * バス乗り場部分のコンポーネント。
 */
const BusRideSpot: React.FC<Props> = (props: Props) => {
  const state = useAppState();
  if (state.courses.length === 0) {
    return null;
  }
  const course = state.courses[state.courseIndex];

  /** 矢印を黄色にするかを表すフラグ。 */
  const isYellowArrow = () => {
    /** 経路の最初の区間。 */
    // 整形後の経路を渡すため、index=0を指定している。
    const firstLine = course.Route.Line[0];
    /** 最初の区間の会社名。 */
    const corpName = firstLine.TypicalName ? firstLine.TypicalName : '';
    /** 最初の区間の路線名。 */
    const lineName = getBusSystemNumber(firstLine.TypicalName || '');
    return isYellow(props.busStopCode, props.busRideSpot, corpName, lineName);
  };

  /** 会社名を取得する */
  const getCorporationName = () => {
    if (course === null) {
      return false;
    }
    /** 経路の最初の区間。 */
    const firstLine = course.Route.Line[0];
    /** 会社名を返す */
    return firstLine.Corporation?.Name;
  };

  /**
   * サイネージ設置位置に対応するバス停情報を取得する。
   * @returns サイネージ設置位置に対応するバス停情報。
   */
  const getBusStop = () => {
    if (props.busStopCode) {
      const busStopInfo = NEAR_BUSSTOP_INFOMATION[props.busStopCode];
      if (!busStopInfo) {
        return '';
      }
      return busStopInfo[props.busRideSpot];
    }
    return '';
  };

  /** サイネージ設置位置に対応するバス停情報。 */
  const busStop = getBusStop();
  /** 会社名を取得する */
  const corporationName = getCorporationName();
  const busRideSpotString =
    corporationName === '名鉄バス' ? '番のりば横' : '番のりば';

  return (
    <Grid container direction="column">
      <Grid>名古屋市バス</Grid>
      <Grid item>
        <Typography variant="h6">
          {props.busRideSpot}
          {busRideSpotString}
        </Typography>
      </Grid>
      {/* 矢印。 */}
      {busStop && (
        <Grid item>
          <Arrow
            label={busStop.label}
            direction={busStop.direction}
            yellow={isYellowArrow()}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default BusRideSpot;
