import React from 'react';
import { Grid, Typography, styled } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { getMixwayURL } from '../utils/qr/mixway';
import { BUS_STOP_CODES } from '../utils/api/search-course/nagoya-city/bus-stop-codes';

/**
 * QRコード部分のコンポーネント。
 */
interface Props {
  busStopCode: string;
}

const QRCodeArea: React.FC<Props> = (props: Props) => {
  const QRCodeAreaGrid = styled(Grid)({
    marginTop: 20,
    background: '#FFFFFF',
  });
  const mixwayUrl = getMixwayURL();
  const norikaenaviTopUrl = 'https://www.kotsu.city.nagoya.jp/jp/sp/route/';

  /** 広小路本町か納屋橋かを判定しURLを設定する */
  const busCorpUrl = (() => {
    if (props.busStopCode === BUS_STOP_CODES.nayabashi_2) {
      return 'https://www.kotsu.city.nagoya.jp/jp/sp/bus/access.html?start=%E7%B4%8D%E5%B1%8B%E6%A9%8B';
    } else {
      return 'https://www.kotsu.city.nagoya.jp/jp/sp/bus/access.html?start=%E5%BA%83%E5%B0%8F%E8%B7%AF%E6%9C%AC%E7%94%BA';
    }
  })();

  return (
    <QRCodeAreaGrid container textAlign="center">
      {/* mixway webのURLのQRコード。 */}
      <Grid item xs={6}>
        <Typography variant="body2" style={{ marginBottom: 10 }}>
          さらに詳しい経路はこちら
        </Typography>
        <QRCodeSVG value={norikaenaviTopUrl} size={100} />
      </Grid>
      {/* 名古屋市営バスの接近情報ページのURLのQRコード。 */}
      <Grid item xs={6}>
        <Typography variant="body2" style={{ marginBottom: 10 }}>
          バスの最新運行情報はこちら
        </Typography>
        <QRCodeSVG value={busCorpUrl} size={100} />
      </Grid>
    </QRCodeAreaGrid>
  );
};

export default QRCodeArea;
