import { Line, Type } from '../../types/mixway-api/extreme';
import { BUS_STOP_CODES } from './search-course/nagoya-city/bus-stop-codes';

/** 緯度経度からポール情報を判定するためのリスト */
const POLE_LATLNG_LIST: { [key: string]: { [key: string]: string } } = {
  [BUS_STOP_CODES.nayabashi_2]: {
    '35.168098,136.894069': '1',
    '35.167903,136.89293': '2',
  },
};

/** 名鉄バスの方面からポール情報を判定するためのリスト */
const MEITETSU_POLE_LIST: { [key: string]: { [key: string]: string } } = {
  [BUS_STOP_CODES.nayabashi_2]: {
    栄: '1',
    大坪: '2',
    津島駅: '2',
  },
  [BUS_STOP_CODES.hirokojihonmachi_7]: {
    栄: '2',
    大坪: '3',
    津島駅: '3',
  },
};

/**
 * Line/destination 要素より行き先情報を取得する
 * @param destination 行き先情報
 * @returns
 */
export const getDestination = (destination: string) => {
  /** 行き先を取得するための正規表現。 */
  const regex = /^(.+?)／.*$/;
  // 行き先が含まれていない値の場合はそのまま返却する。
  if (!regex.test(destination)) {
    return destination;
  }
  /** 行き先を取得する */
  const matchDestination = destination.match(regex);
  if (
    matchDestination !== null &&
    Array.isArray(matchDestination) &&
    matchDestination.length > 1
  ) {
    return matchDestination[1];
  } else {
    return destination;
  }
};

/**
 * Line/TypicalName よりバスの系統番号を取得する
 * @param typicalName Lineの代表名称
 */
export const getBusSystemNumber = (typicalName: string) => {
  const splitTypicalName = typicalName.split('・');
  /** 「名古屋市営バス・栄２４」のような形式でない場合にはそのまま返す */
  if (splitTypicalName.length < 2) {
    return typicalName;
  } else {
    // 会社名の部分を取り除いた上で、再度「・」でつないだ文字列を系統名として返却する。
    return splitTypicalName.slice(1).join('・');
  }
};

/**
 * 区間の出発のりば（または番線）を取得する。
 * @param line 区間。
 * @returns 区間の出発のりば（または番線）。
 */
export const getDepartureNo = (line: Line) => {
  return line.DepartureState.no ? line.DepartureState.no : '';
};

/**
 * バスがどの乗り場から発車するかのポール情報を返す
 * @param line バス区間のLine情報
 * @param busStopCode クエリで指定した出発バス停情報
 * @returns
 */
export const getBusStopPoleInfo = (line: Line, busStopCode: string) => {
  let busPoleNumber = '';
  /** 経路から取得したのりば番号。 */
  const departureNumber = getDepartureNo(line);
  if (busPoleNumber) {
    busPoleNumber = departureNumber;
  }

  const destination = getDestination(line.Destination);
  /** 「名鉄バス」は行き先からのりばを決定する */
  if (line.Corporation?.Name === '名鉄バス') {
    if (busStopCode) {
      const matchPoleNumber = MEITETSU_POLE_LIST[busStopCode][destination];
      if (matchPoleNumber) {
        return matchPoleNumber;
      }
    }
  }

  /** バス停が納屋橋の場合には座標から推測する */
  if (busStopCode === BUS_STOP_CODES.nayabashi_2) {
    const latlng = line.DepartureState?.Pole?.GeoPoint;
    if (latlng) {
      const matchPoleNumber =
        POLE_LATLNG_LIST[busStopCode][`${latlng.lati_d},${latlng.longi_d}`];
      if (matchPoleNumber) {
        return matchPoleNumber;
      }
    }
  }
  console.error('ポール情報が見つかりませんでした: ' + busStopCode);
  return '';
};

export enum LINE_TYPE {
  'train' = 'train',
  'bus' = 'bus',
  'walk' = 'walk',
  'other' = 'other',
}

/**
 * 路線タイプを文字列で取得する
 */
export const getLineType = (baseLineType: string | Type): LINE_TYPE => {
  if (!baseLineType) {
    return LINE_TYPE.other;
  }
  if (typeof baseLineType === 'string') {
    switch (baseLineType) {
      case 'walk':
        return LINE_TYPE.walk;
      default:
        return LINE_TYPE.other;
    }
  }
  switch (baseLineType.text) {
    case 'train':
      return LINE_TYPE.train;
    case 'bus':
      return LINE_TYPE.bus;
    default:
      return LINE_TYPE.other;
  }
};

/**
 * 経路の地点（駅）の種別の文字列を取得する。
 * @param type 駅の種別情報。
 * @returns 駅の種別の文字列。
 */
export function getStationType(type: string | Type) {
  // type要素が文字列の場合はそのまま返却する。
  if (typeof type === 'string') {
    return type;
  }
  // type要素がObjectで「text」要素が設定されている場合はその値を返却する。
  if (type.text) {
    return type.text;
  }
  // それ以外の場合は「other」を返却する。
  return 'other';
}

/**
 * 所要時間（分）をxx時間xx分形式の文字列にする
 * @param time 所要時間
 * @param isChangePlatform7 広小路本町7番対応が必要か
 * @returns
 */
export const getTimeOnBoardString = (
  time: number,
  isChangePlatform7: boolean
) => {
  if (isChangePlatform7) {
    /** 出発時間を1分プラスするため、所要時間は1分減らす */
    time -= 1;
  }
  if (time < 60) {
    return `${time}分`;
  } else {
    return `${Math.floor(time / 60)}時間${time % 60}分`;
  }
};

/**
 * バス停など事業者名が含まれている駅名を修正する。
 * @param value 修正したい駅名。
 * @returns 修正後の駅名。
 */
export function fixStationName(value: string) {
  return value.replace(/^(.*?)／.*$/, '$1').replace(/^(.*?)\(.*\)/, '$1');
}

export const getShortStationName = (stationName: string | null) => {
  if (stationName === null) {
    return null;
  }
  return fixStationName(stationName);
};
