import { useAppState } from '../../contexts/AppStateContext';
import {
  getLineType,
  getShortStationName,
  getStationType,
  LINE_TYPE,
} from '../../utils/api/mixway-utils';
import Point from './Point';
import Line from './Line';
import { toHHmm } from '../../utils/datetime';
import { getCorporationName, getWalkLabel } from '../../utils/course/line';
import { getStationSuffix } from '../ArrivalGuide/utils';
import { getDestinationName } from '../../utils/course/point';

/** 経路のコンポーネント。 */
const Course: React.FC = () => {
  const state = useAppState();

  const course =
    state.courses.length > 0 ? state.courses[state.courseIndex] : undefined;
  // 表示する経路が空の場合、何も表示しない。
  if (!course) {
    return null;
  }
  /** 目的地の名称。 */
  const arrivalPointName = getDestinationName(course.Route.Point);
  // 以下は表示する経路が存在する場合。
  return (
    <>
      {course.Route.Line.map((line, idx) => {
        /** 経路の区間の配列。 */
        const lines = course.Route.Line;
        /** 区間配列の末尾のインデックス。 */
        const lastLineIndex = course.Route.Line.length - 1;
        /** 最後の区間か */
        const isLastSection = idx === lastLineIndex;
        /** 区間（路線）の色。 */
        const lineColor = state.color.arrow;
        /**
         * 区間インデックスに対応する出発時刻を取得する。
         * @param idx 区間インデックス。
         * @returns 区間インデックスに対応する出発時刻。
         */
        const depDate = (idx: number) => {
          // 最後の区間の場合、出発時刻を表示しないため空文字を返却する。
          if (idx === lastLineIndex) {
            return '';
          }
          return line.DepartureState?.Datetime.text
            ? toHHmm(line.DepartureState.Datetime.text)
            : '';
        };
        /**
         * 区間インデックスに対応する到着時刻を取得する。
         * @param idx 区間インデックス。
         * @returns 区間インデックスに対応する到着時刻。
         */
        const arrDate = (idx: number) => {
          // 最初の区間を参照している場合は、直前の区間が存在しないため空文字を返却する。
          if (idx === 0) {
            return '';
          }
          /** 直前の区間インデックス。 */
          const previousIdx = idx - 1;
          /** 直前の区間の到着情報。 */
          // レイアウトの都合、到着時刻は直前の区間の到着日時を設定している。
          const previousLineArrivalState = lines[previousIdx].ArrivalState;

          // 到着日時が設定されている場合は、時刻の文字列を設定する。
          return previousLineArrivalState
            ? toHHmm(previousLineArrivalState.Datetime.text)
            : '';
        };
        const point = course.Route.Point[idx];
        const depStationName = course?.Route.Point[idx].Station?.Name;
        /** 区間の種別。 */
        const lineType = getLineType(line.Type);
        /** 駅名の末尾に付与するラベル。 */
        const stationNameLabel = point.Station?.Type
          ? getStationSuffix(getStationType(point.Station.Type))
          : '';
        /** 駅名。 */
        const label = depStationName
          ? `${getShortStationName(depStationName)}${stationNameLabel}`
          : '';
        /** 区間の乗車時間（所要時間）。 */
        const timeOnBoard = Number(line.timeOnBoard);
        /** 区間の名称。 */
        const lineName =
          lineType === LINE_TYPE.walk
            ? getWalkLabel(timeOnBoard, isLastSection)
            : getCorporationName(line.Name);

        return (
          <div key={`course_${idx}`}>
            <Point
              depDate={depDate(idx)}
              arrDate={arrDate(idx)}
              label={label}
            />
            <Line
              lineType={lineType}
              stopStationCount={line.stopStationCount}
              lineColor={lineColor}
              name={lineName}
              destination={line?.Destination}
              typicalName={line?.TypicalName}
            />
            {/* 最後の地点（目的地）。 */}
            {idx === course.Route.Line.length - 1 &&
              line.ArrivalState?.Datetime.text && (
                <Point
                  arrDate={toHHmm(line.ArrivalState.Datetime.text)}
                  label={arrivalPointName}
                />
              )}
          </div>
        );
      })}
    </>
  );
};

export default Course;
