import * as React from 'react';
import {
  Button,
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  List,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import { useAppState } from '../../../contexts/AppStateContext';
import { BusTimetableParams } from '../../../utils/api/bus-timetable/params';
import { getBusTimetable } from '../../../utils/api/ews';
import { ListItem } from './ListItem';
import LoadingCircle from '../../LoadingCircle';
import { filterTimetable, isNorthBusPole } from '../../../utils/kozoji';
import { toJSTDatetime } from '../../../utils/datetime';
import { RailLine } from '../../../types/mixway-api/rail';
import { NotExists } from './NotExists';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const NextBusDialog: React.FC = () => {
  const state = useAppState();
  const [isLoading, switchIsLoading] = React.useState(false);
  /** 前後の列車ダイアログを表示するか。 */
  const [openDialog, setOpenDialog] = React.useState(false);
  /** 取得した前後のバス一覧 */
  const [timetable, setTimetable] = React.useState<RailLine[]>([]);
  const closeDialog = () => {
    setOpenDialog(false);
  };
  /** 現在表示している経路。 */
  const course = state.courses[state.courseIndex];
  /** 経路の最初の区間。 */
  const firstLine = course.Route.Line[0];
  /** 経路の最初の区間の出発日時。 */
  const departureDateTime = toJSTDatetime(
    firstLine.DepartureState.Datetime.text
  );
  /** 経路の最初の区間の出発日付（yyyyMMdd形式の文字列）。 */
  const departureDate = departureDateTime.toFormat('yyyyMMdd');

  /** 最初の区間の出発バス停コード。 */
  const depCode = course.Route.Point[0].Station?.code
    ? course.Route.Point[0].Station.code
    : '';
  /** 最初の区間の到着バス停コード。 */
  const arrCode = course.Route.Point[1].Station?.code
    ? course.Route.Point[1].Station.code
    : '';
  /** 北口のバス停であるかを表すフラグ。 */
  const isNorth = isNorthBusPole(depCode);

  /** 「つぎの便」ボタンを押したときの処理。 */
  const onClicked = async () => {
    // Dialog部分を開く。
    setOpenDialog(true);
    // 出発バス停・到着バス停の駅コードが取得できた場合のみ、バス時刻表APIにリクエストする。
    if (depCode && arrCode) {
      // 読み込み中フラグをオフにする。
      switchIsLoading(true);
      const params = new BusTimetableParams(depCode, arrCode, departureDate);
      getBusTimetable(params)
        .then((timetable) => {
          const filtered = filterTimetable(timetable, departureDateTime);
          if (filtered.length > 0) {
            // バス時刻表が取得できた場合は格納する。
            setTimetable(filtered);
          }
        })
        .finally(() => {
          // かならず読み込み中フラグをオフにする。
          switchIsLoading(false);
        });
    }
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={onClicked}
        style={{
          color: '#000000',
          backgroundColor: '#f6f6f6',
        }}
      >
        つぎの便を検索する
      </Button>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={closeDialog}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', backgroundColor: '#505050' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1, fontWeight: 'bold' }}
              variant="h4"
              component="div"
            >
              つぎの便
            </Typography>
          </Toolbar>
        </AppBar>
        {/* 読み込み中の場合、 */}
        {isLoading && <LoadingCircle />}
        {/* 読み込み中ではない場合かつ、時刻表データを取得できなかった場合はその旨を表示する。 */}
        {!isLoading && timetable.length === 0 && <NotExists />}
        <List>
          {/** 取得した前後のバス一覧を表示する */}
          {timetable.map((busLine, idx) => {
            return (
              <ListItem
                line={busLine}
                key={`timetable_item_${idx}`}
                isNorth={isNorth}
              />
            );
          })}
        </List>
      </Dialog>
    </>
  );
};
