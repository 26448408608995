import React, { useEffect, useRef, useState } from 'react';
import { Alert, Divider, Grid, Box, styled } from '@mui/material';
import { SwapVertOutlined } from '@mui/icons-material';
import { useAppState } from '../contexts/AppStateContext';
import { DateTime } from 'luxon';
import Course from './Course';
import {
  getBusStopPoleInfo,
  getBusSystemNumber,
  getDestination,
} from '../utils/api/mixway-utils';
import QRCodeArea from './QR';
import BusRideSpot from './Header/BusRideSpot';
import { StyledComponent } from '@emotion/styled';
import ReSearch from './ReSearch';
import { isMatchCourse, isReplacementPlatform7 } from '../utils/nagoya-city';
import MapArea from './Map';

interface Props {
  /** パラメーターに関するエラーがあるかを表すフラグ。 */
  hasInvalidParams: boolean;
  /** バス停コード。 */
  busStopCode: string;
  /** 出発日時。 */
  depDate: DateTime;
  /** 目的地の名称。 */
  arrivalPointName: string;
  /** 目的地の緯度経度。 */
  arrivalPointPosition: string;
}

/**
 * 経路検索成功時に結果の経路を表示するコンポーネント
 */
const Result: React.FC<Props> = (props: Props) => {
  const state = useAppState();

  /** バス出発時刻 */
  const [depBusDate, setBusDepDate] = useState<DateTime>();
  /** バス行き先 */
  const [destination, setDestination] = useState<string>('');
  /** バス系統番号 */
  const [busSystemNumber, setBusSystemNumber] = useState<string>('');
  /** バス乗り場情報 */
  const [busRideSpot, setBusRideSpot] = useState<string>('');
  /** バス会社名 */
  const [companyName, setCompanyName] = useState<string>('');
  /** バスが出発済みか */
  const [isBusAlreadyLeft, switchIsBusAlreadyLeft] = useState<boolean>(false);
  /** ヘッダー固定部分初期スタイル */
  const initHeaderStickyGridStyle: { [key: string]: string | number } = {
    background: state.color.header,
    color: '#FFFFFF',
    position: 'sticky',
    top: 0,
    zIndex: 1,
    textAlign: 'center',
  };

  /** ヘッダー固定部分のスタイル */
  const [HeaderStickyGrid, setHeaderStickyGrid] = useState<
    StyledComponent<any>
  >(
    styled(Grid)({
      initHeaderStickyGridStyle,
    })
  );

  /** ヘッダー固定部分の色を変える */
  useEffect(() => {
    const newStyle: { [key: string]: string | number } = {
      ...initHeaderStickyGridStyle,
      background: state.color.header,
    };
    setHeaderStickyGrid(styled(Grid)(newStyle));
  }, [state.color.header]);

  /** ヘッダーコンテンツ部分 */
  const CenterContentGrid = styled(Grid)({
    height: '150px',
    maxWidth: '681px',
    margin: 'auto',
  });

  /** 1秒毎に表示中の経路が出発済みかを判定する */
  useEffect(() => {
    const timerID = setInterval(() => {
      if (state.courses.length === 0) {
        return;
      }
      const targetCourse = state.courses[state.courseIndex];
      const currentTime = DateTime.now().set({
        second: 0,
        millisecond: 0,
      });
      /** 参照している経路の最初の区間。 */
      const firstLine = targetCourse.Route.Line[0];
      /** バス出発時刻を過ぎた場合タイマーを停止する */
      const stopTime = !isMatchCourse(
        firstLine,
        props.busStopCode,
        currentTime
      );
      switchIsBusAlreadyLeft(stopTime);
      if (stopTime) {
        clearInterval(timerID);
      }
    }, 1000);
  }, [state.courses[state.courseIndex || 0]]);

  useEffect(() => {
    try {
      const targetCourse = state.courses[state.courseIndex];
      if (!targetCourse) {
        return;
      }
      const firstLine = targetCourse.Route?.Line[0];
      const depDate = DateTime.fromISO(
        firstLine?.DepartureState?.Datetime?.text
      );
      /** TODO ↓↓↓ 広小路本町7番のポール情報が整備されるまでの特別措置 ↓↓↓ */
      const firstPoint = targetCourse.Route?.Point[0];
      /** 広小路本町2番のりばを7番に置き換える必要があるか */
      const isNeedReplacement = isReplacementPlatform7(firstLine, firstPoint);
      if (isNeedReplacement) {
        /** 2番でなく7番を案内するため、1分進める */
        const depDateAdjusted = depDate.plus({ minute: 1 });
        /** 出発時刻 */
        setBusDepDate(depDateAdjusted);
        /** バスの乗り場を7番に置き換える */
        setBusRideSpot('7');
      } else {
        /** 出発時刻 */
        setBusDepDate(depDate);
        /** バスの乗り場を取得する */
        setBusRideSpot(getBusStopPoleInfo(firstLine, props.busStopCode));
      }
      /** ↑↑↑ 広小路本町7番のポール情報が整備されるまでの特別措置 ↑↑↑ */

      /** 行き先を取得する */
      setDestination(getDestination(firstLine.Destination));
      /** バスの系統番号を取得する */
      setBusSystemNumber(getBusSystemNumber(firstLine.TypicalName || ''));
      /** バス会社名を入れ込む */
      setCompanyName(firstLine.Name.split('・')[0]);
    } catch (e) {
      console.error(e);
    }
  }, [JSON.stringify(state)]);

  /** フッター固定部分 */
  const FooterContentGrid = styled(Grid)({
    zIndex: 2,
    background: '#FFFFFF',
  });

  const HeaderGrid = styled(Grid)({
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  });

  /** 「発車予定」の文字列を表示するスタイル */
  const DepScheduleSpan = styled('span')({
    fontSize: '20px',
    position: 'relative',
    left: '35px',
  });

  /** 出発時刻を表示するスタイル */
  const DepTimeSpan = styled('span')({
    fontSize: '64px',
  });

  /** 行き先のスタイル */
  const DestinationSpan = styled('span')({
    fontSize: '20px',
  });

  /** 経路表示部分のRefオブジェクトを格納する変数。 */
  const courseRef = useRef<HTMLDivElement>(null);
  /** 経路表示部分がスクロール可能であるかを表すフラグ。 */
  const [isScrollable, setScrollable] = useState(false);

  // 経路表示部分の高さが変わる度に発火する関数。
  useEffect(() => {
    /** 経路表示部分の高さ。 */
    const currentHeight = courseRef?.current?.clientHeight;
    // 高さの値が取得できなかった場合は何もしない。
    if (!currentHeight) {
      return;
    }
    // 2023/09/08現在試したところ、340pxを超えるとスクロールが必要な高さになる想定のため、
    // 340pxを超えたか判定した値をスクロール可能であるかを表すフラグとして設定する。
    setScrollable(currentHeight > 340);
  }, [courseRef?.current?.clientHeight]);

  return (
    <Grid container direction="column">
      <HeaderStickyGrid item>
        {state.isDummyCourse && (
          <Alert severity="error">これはダミー経路です。</Alert>
        )}
        <CenterContentGrid container alignItems="center">
          <Grid item xs={4}>
            {/** バスが出発済みでないなら次のバスの出発時刻を表示する */}
            {!isBusAlreadyLeft && (
              <Grid container direction="column">
                <HeaderGrid item>
                  <DepTimeSpan>{depBusDate?.toFormat('HH:mm')}</DepTimeSpan>
                </HeaderGrid>
                <HeaderGrid item>
                  <DepScheduleSpan>発車予定</DepScheduleSpan>
                </HeaderGrid>
              </Grid>
            )}
            {/** バスが出発済みなら再検索ボタンを表示する */}
            {isBusAlreadyLeft && (
              <Grid container direction="column">
                <HeaderGrid item>
                  <span
                    style={{
                      fontSize: '40px',
                    }}
                  >
                    発車済み
                  </span>
                </HeaderGrid>
                <HeaderGrid item>
                  {/* パラメーターに関するエラーがない場合のみ、再検索可能とする。 */}
                  {!props.hasInvalidParams && (
                    <ReSearch
                      arrivalPoineName={props.arrivalPointName}
                      arrivalPointPosition={props.arrivalPointPosition}
                      busStopCode={props.busStopCode}
                      depDate={props.depDate}
                    />
                  )}
                </HeaderGrid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={4}>
            {/** 系統表示 */}
            <Grid container direction="column">
              {/** バス会社の表示 */}
              <HeaderGrid item>
                <DestinationSpan>{companyName}</DestinationSpan>
              </HeaderGrid>
              {/** バス系統番号表示 */}
              {busSystemNumber && (
                <HeaderGrid item>
                  <DestinationSpan>[{busSystemNumber}]</DestinationSpan>
                </HeaderGrid>
              )}
              {/** バス行き先表示 */}
              <HeaderGrid item>
                <DestinationSpan>{destination} 行</DestinationSpan>
              </HeaderGrid>
            </Grid>
          </Grid>
          {/* バスのりば部分。 */}
          <Grid item xs={4}>
            {busRideSpot !== '' && (
              <BusRideSpot
                busRideSpot={busRideSpot}
                busStopCode={props.busStopCode}
              />
            )}
          </Grid>
        </CenterContentGrid>
      </HeaderStickyGrid>
      {/* 長めの経路で全体を確認するにはスクロールが必要な場合、スクロールを促すアイコンを表示する。 */}
      {isScrollable && (
        <Box sx={{ position: 'fixed', top: 540, right: 16 }}>
          <SwapVertOutlined sx={{ fontSize: 40, color: '#313C3D' }} />
        </Box>
      )}
      {/** 経路表示部分 */}
      {state.courses.length !== 0 && (
        <Grid item ref={courseRef}>
          <Course />
        </Grid>
      )}
      <FooterContentGrid item>
        <Divider style={{ marginTop: 10 }} />
        <QRCodeArea busStopCode={props.busStopCode} />
        <MapArea busStopCode={props.busStopCode} />
      </FooterContentGrid>
    </Grid>
  );
};

export default Result;
