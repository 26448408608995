/** 環境変数に関する処理のファイル。 */

/**
 * keyパラメーターで指定された値が有効か判定する。
 * @param key keyパラメーターで設定された値。
 * @returns 値が有効なものであるか判定した結果。
 */
export const isValidKey = (key: string) => {
  // 環境変数「REACT_APP_ACCESS_KEY」の値と一致していれば有効とみなす。
  return key === process.env['REACT_APP_ACCESS_KEY'];
};
