import { Point } from '../../types/mixway-api/extreme';

/**
 * 経路の目的地（Point配列の末尾の要素）の名称を取得する。
 * @param points 経路の地点の配列。
 * @returns 目的地の名称。
 */
export const getDestinationName = (points: Point[]) => {
  /** 経路の地点（Point配列）の最後のインデックス。 */
  const lastIndex = points.length - 1;
  const destinationName = points[lastIndex].Name;
  return destinationName ? destinationName : '';
};

/**
 * 経路の最初の駅（バス停）の駅コードを取得する。
 * @param points 経路の地点（駅・バス停など）の配列。
 * @returns 最初の駅の駅コード。
 */
export const getDepartureStationCode = (points: Point[]) => {
  const firstStationCode = points[0].Station?.code;
  return firstStationCode ? firstStationCode : '';
};
