import { ArrowProps } from '..';

export const Right: React.FC<ArrowProps> = (props: ArrowProps) => {
  return (
    <svg
      width={props.width}
      viewBox="0 0 63.499999 15.874999"
      version="1.1"
      id="svg1"
    >
      <defs id="defs1">
        <linearGradient id="swatch1">
          <stop stopColor={props.color} stopOpacity={1} offset="0" id="stop1" />
        </linearGradient>
      </defs>
      <g id="layer1">
        <path
          id="rect1"
          fill={props.color}
          fillOpacity={1}
          stroke="#000000"
          strokeWidth={0.377577}
          strokeDasharray="none"
          strokeOpacity={1}
          d="m 55.161949,0.52517393 0.01099,3.86520097 H 0.22916817 V 12.306917 H 55.194929 l 0.0088,3.142913 3.968136,-3.225642 3.968137,-3.2256408 -3.989024,-4.2366868 z"
        />
        <text
          fontSize="4.5px"
          fill={props.color}
          fillOpacity={1}
          stroke="#002060"
          strokeWidth={0.264999}
          strokeDasharray="none"
          strokeOpacity={11}
          id="text1"
        >
          <tspan
            id="tspan1"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
            fontStretch="normal"
            fontSize="4.5px"
            fontFamily="Noto Sans Gothic"
            fill={props.textColor}
            stroke="none"
            strokeWidth={0.265}
            x="3"
            y="10"
          >
            {props.label}
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default Right;
