import { ArrowProps } from '..';

/** 右下方向の矢印のコンポーネント。 */
export const RightDown: React.FC<ArrowProps> = (props: ArrowProps) => {
  return (
    <svg
      width={props.width}
      viewBox="0 0 63.499999 15.874999"
      version="1.1"
      id="svg1"
    >
      <defs id="defs1">
        <linearGradient id="swatch1">
          <stop stopColor={props.color} stopOpacity={1} offset="0" id="stop1" />
        </linearGradient>
      </defs>
      <g id="layer1">
        <path
          id="rect1"
          fill={props.color}
          fillOpacity={1}
          strokeWidth={0.346075}
          strokeDasharray="none"
          stroke="#002060"
          strokeOpacity={1}
          d="M 57.755985,15.267586 55.212259,12.086147 52.668535,8.903961 h 2.74809 V 7.2800001 H 0.46566128 V 0.70049316 H 55.416625 v -0.0052 h 5.077644 V 8.903961 h 2.349681 l -2.543724,3.182186 z"
        />
        <text
          fontStyle="normal"
          fontVariant="normal"
          fontWeight="normal"
          fontStretch="normal"
          fontSize="4.5px"
          fontFamily="Noto Sans Gothic"
          fill={props.textColor}
          stroke="none"
          strokeWidth={0.264999}
          id="text1"
        >
          <tspan
            id="tspan1"
            fontSize="4.5px"
            fill={props.textColor}
            stroke="none"
            strokeWidth={0.265}
            x="3"
            y="5.5"
          >
            {props.label}
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default RightDown;
