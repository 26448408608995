import { CircularProgress, Grid } from '@mui/material';

/** 読み込み中を表す円のコンポーネント。 */
const LoadingCircle: React.FC = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

export default LoadingCircle;
