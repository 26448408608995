import { isCoordinate } from './string';

/** 緯度経度のクラス。 */
// 緯度・経度を分けて使いたい場合にのみ利用する想定。
export class Coordinate {
  /** 緯度。 */
  lat: number;
  /** 経度。 */
  lng: number;

  /**
   * コンストラクタ。
   * @param value 緯度経度がセットになっている文字列。
   */
  constructor(value: string) {
    // 指定された値が緯度経度の形式になっている場合。
    if (isCoordinate(value)) {
      const splitted = value.split(',');
      const lat = Number(splitted[0]);
      const lng = Number(splitted[1]);
      this.lat = Number.isNaN(lat) ? 0 : lat;
      this.lng = Number.isNaN(lng) ? 0 : lng;
    } else {
      // 指定された値が緯度経度の形式になっていない場合。
      this.lat = 0;
      this.lng = 0;
    }
  }
  /** 緯度経度が想定通りの値ではないかを判定する。 */
  isInvalid() {
    return this.lat === 0 && this.lng === 0;
  }
}
